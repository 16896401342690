import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Usage extends React.Component {
	render() {
		const usagedata = this.props.article.fields;

		var usageDescription;
		var suggestedDescription;
		// var storageInstr;

		// if (usagedata.nonFoodInstruction.fields.storageInstructionDescription === undefined || usagedata.nonFoodInstruction.fields.storageInstructionDescription === 'Not Applicable'
		// ){
		// 	storageInstr = null;
		// }else{
		// 	storageInstr=(
		// 		<div className="definition">
		// 			<Row>
		// 				<Col sm="12" xs="12">
		// 					<h4 className="header4" id='usage-header'>{usagedata.nonFoodInstruction.fields.storageInstructionTitle}</h4>
		// 					<p id='usage' >{usagedata.nonFoodInstruction.fields.storageInstructionDescription}</p>
		// 				</Col>
		// 			</Row>
		// 		</div>
		// 	);
		// }

		if (
			usagedata.nonFoodInstruction.fields.usageInstructionDescription ===
			undefined
		) {
			usageDescription = null;
		} else if (
			usagedata.nonFoodInstruction.fields.usageInstructionDescription ===
				'N/A' ||
			usagedata.nonFoodInstruction.fields.usageInstructionDescription ===
				'Not Applicable' ||
			usagedata.nonFoodInstruction.fields.usageInstructionDescription ===
				'Not Available' ||
			usagedata.nonFoodInstruction.fields.usageInstructionDescription ===
				'NA'
		) {
			usageDescription = (
				<div className='definition'>
					<Row>
						<Col sm='12' xs='12'>
							<h4 className='header4' id='usage-header'>
								{
									usagedata.nonFoodInstruction.fields
										.usageInstructionTitle
								}
							</h4>
							<p id='usage'>Not Applicable</p>
						</Col>
					</Row>
				</div>
			);
		} else {
			usageDescription = (
				<div className='definition'>
					<Row>
						<Col sm='12' xs='12'>
							<h4 className='header4' id='usage-header'>
								{
									usagedata.nonFoodInstruction.fields
										.usageInstructionTitle
								}
							</h4>
							<p id='usage'>
								{
									usagedata.nonFoodInstruction.fields
										.usageInstructionDescription
								}
							</p>
						</Col>
					</Row>
				</div>
			);
		}

		if (
			usagedata.nonFoodInstruction.fields.suggestedUseDescription ===
				undefined ||
			usagedata.nonFoodInstruction.fields.suggestedUseDescription ===
				'Not Applicable' ||
			usagedata.nonFoodInstruction.fields.suggestedUseDescription ===
				'Not Available' ||
			usagedata.nonFoodInstruction.fields.suggestedUseDescription ===
				'NA' ||
			usagedata.nonFoodInstruction.fields.suggestedUseDescription ===
				'N/A'
		) {
			suggestedDescription = null;
		} else if (
			usagedata.nonFoodInstruction.fields.suggestedUseDescription.includes(
				'NPN '
			)
		) {
			suggestedDescription = null;
		} else if (
			usagedata.nonFoodInstruction.fields.suggestedUseDescription.includes(
				'DIN '
			)
		) {
			suggestedDescription = null;
		} else {
			suggestedDescription = (
				<div className='definition'>
					<Row>
						<Col sm='12' xs='12'>
							<h4 className='header4' id='usage-header'>
								{
									usagedata.nonFoodInstruction.fields
										.suggestedUseTitle
								}
							</h4>
							<p id='usage'>
								{
									usagedata.nonFoodInstruction.fields
										.suggestedUseDescription
								}
							</p>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<Container fluid className='top-20'>
				{usageDescription}
				{suggestedDescription}
				{/* {this.props.local!=='en-US' && storageInstr} */}
			</Container>
		);
	}
}
