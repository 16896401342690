/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import SupplementTable from './supplement-table';

export default function Nutrition(props) {
	const allSupplements = props.article.fields.supplementNutritionFact;
	const isKIT =
		allSupplements && allSupplements[0] && allSupplements[0].fcgcas
			? true
			: false;
	const selectElement =
		isKIT && document && document.getElementById('select_id');
	const chosenProduct =
		selectElement && JSON.parse(selectElement.value).fcgcas;
	const chosenTable =
		chosenProduct && allSupplements.find((i) => i.fcgcas === chosenProduct);
	const supplement = isKIT
		? chosenTable
			? chosenTable.data
			: null
		: allSupplements;
	const panelData = props.article.fields.panel
		? props.article.fields.panel.toLowerCase() === 'supplement'
			? 'supplement'
			: 'nutrition'
		: null;
	const [arrID, setArrID] = useState('0');

	const handleChange = (e) => {
		setArrID(JSON.parse(e.target.value).id.toString());
	};
	const supProps = { supplement, panelData, arrID, handleChange };
	return supplement ? (
		<SupplementWrapper {...supProps} />
	) : (
		<section className='supplement'>
			<span className='kit-supplement-na'>Not Applicable</span>
		</section>
	);
}

const SupplementWrapper = (props) => {
	const { supplement, panelData, arrID, handleChange } = props;
	let toDisplay;
	const { ServingSize, Calories, Nutrients } = supplement;
	const isSingle = ServingSize.data.length ? false : true;

	const CaloriesData = Calories
		? isSingle
			? Calories.data.value
			: Calories.data[arrID].value
		: undefined;

	const returnValues = (text) => {
		const nutrient = Nutrients.filter((i) =>
			i.title.toLowerCase().includes(text)
		)[0];
		const data = nutrient
			? isSingle
				? nutrient.data.value
				: nutrient.data[arrID].value
			: null;
		const title = nutrient ? nutrient.title.replace(/>/g, '') : null;
		if (data && title) return { data, title };
		else return null;
	};
	if (panelData === 'nutrition') {
		toDisplay = [
			Calories && { data: CaloriesData, title: Calories.title },
			returnValues('total sugars'),
			returnValues('total fat'),
			returnValues('trans fat'),
			returnValues('sodium'),
			returnValues('cholesterol')
		];
	}
	return (
		<React.Fragment>
			{panelData === 'nutrition' && (
				<div className='topvalues'>
					{toDisplay.map(
						(topValue, id) =>
							topValue && (
								<div key={id} className='eachvalue'>
									<div className='topstyle'>
										<span>
											<h2>
												{topValue.data === '0mg' ||
												topValue.data === '0g'
													? 0
													: topValue.data}
											</h2>
											<h3>
												{topValue.title.includes(
													'Trans'
												) && (
													<span className='oblique'>
														{
															topValue.title.match(
																/Trans/
															)[0]
														}
													</span>
												)}
												<span>
													{topValue.title
														.replace(/>/g, '')
														.replace(/Trans/g, '')}
												</span>
											</h3>
										</span>
									</div>
								</div>
							)
					)}
				</div>
			)}
			{!isSingle && (
				<div className='select'>
					<select onChange={handleChange} className='dropdown'>
						{ServingSize.data.map((i, id) => (
							<option
								key={id}
								value={JSON.stringify({
									value: i.value,
									id: id
								})}
							>
								{i.value}
							</option>
						))}
					</select>
				</div>
			)}
			<SupplementTable
				supplement={supplement}
				arrID={arrID}
				isSingle={isSingle}
			/>
		</React.Fragment>
	);
};
