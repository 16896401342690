import React from 'react';
import NewNutrition from './nutrition';
import OldNutrition from './nutrition copy';

export default function nutritionWrapper(props) {
	return (
		<React.Fragment>
			{props.article.fields.supplementNutritionFact ? (
				<NewNutrition article={props.article} />
			) : (
				<OldNutrition article={props.article} />
			)}
		</React.Fragment>
	);
}
