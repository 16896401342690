/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Row, Col } from 'reactstrap';
import anchorme from 'anchorme';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class Accordion extends React.Component {
	constructor() {
		super();
		this._handleClick = this._handleClick.bind(this);
		this.state = { articles: [] };
	}

	componentDidMount() {
		this._handleClick();
	}

	_handleClick() {
		const acc = this._acc.children;

		for (let i = 0; i < acc.length; i++) {
			let a = acc[i];
			a.onclick = () => {
				if (a.classList.contains('active')) {
					a.classList.remove('active');
				} else {
					var parentDiv = a.parentNode;
					var arr = parentDiv.children;
					for (i = 0; i < arr.length; i++) {
						arr[i].classList.remove('active');
					}
					a.classList.toggle('active');
				}
			};
		}
	}

	OtherInfo = () => {
		// document.getElementById('all-other-info').style.display='none';
		document.getElementById('accord').style.display = 'block';
		var getElement = document.querySelectorAll('.accor');
		var getactiveEle = document.querySelectorAll('.active');
		for (let i = 0; i < getElement.length; i++) {
			getElement[i].style.display = 'block';
		}
		for (let i = 0; i < getactiveEle.length; i++) {
			getactiveEle[i].classList.remove('active');
		}
	};

	render() {
		return (
			<div ref={(a) => (this._acc = a)} onClick={this._handleClick}>
				{/* <div className='back' id='all-other-info' onClick={this.OtherInfo}>					
					<h2 className='header4'><i className='fa fa-chevron-left fa-5' aria-hidden='true'></i> Back to Other Information</h2>
				</div> */}
				{this.props.children}
			</div>
		);
	}
}

export default class Otherinfo extends React.Component {
	constructor() {
		super();
		this.toggle = this.toggle.bind(this);
		this.state = {
			modal: false,
			modalLink: '',
		};
	}
	toggle(url) {
		this.setState({
			modal: !this.state.modal,
			modalLink: url
		});
	}
	render() {
		const { locale } = this.props.article.sys;

		var sustainability;
		var generalInfo;
		let freeFromDes;
		const otherdata = this.props.article.fields;
		let sustain = otherdata.nonFoodInstruction.fields.sustainabilityDescription;
		let sustainlink;
		if (sustain !== null && sustain !== undefined) {
			sustainlink = anchorme(sustain, {
				attributes: [
					{
						name: 'target',
						value: '_blank',
					},
					{
						name: 'rel',
						value: 'noreferrer noopener',
					},
				],
			});
		} else {
			sustainlink = null;
		}

		if (
			otherdata.nonFoodInstruction.fields.sustainabilityDescription === undefined ||
			otherdata.nonFoodInstruction.fields.sustainabilityDescription === 'N/A' ||
			otherdata.nonFoodInstruction.fields.sustainabilityDescription === 'Not Available' ||
			otherdata.nonFoodInstruction.fields.sustainabilityDescription === 'NA' ||
			otherdata.nonFoodInstruction.fields.sustainabilityDescription === 'Not Applicable'
		) {
			sustainability = null;
		} else {
			sustainability = (
				<div className='accor' id='accord'>
					<div className='header1'>
						{/* eslint-disable-next-line */}
						<a>{otherdata.nonFoodInstruction.fields.sustainabilityTitle}</a>
					</div>
					<div className='body'>
						<div className='other-cont pl-20 pb-20'>
							<p
								className='paragraph'
								dangerouslySetInnerHTML={{
									__html: sustainlink,
								}}
							></p>
						</div>
					</div>
				</div>
			);
		}

		let genInfo =
			otherdata.nonFoodInstruction.fields.generalInfoDescription !== null
				? otherdata.nonFoodInstruction.fields.generalInfoDescription.includes('NOT AVAIABLE ON ARTWORK')
				: null;

		if (
			otherdata.nonFoodInstruction.fields.generalInfoDescription !== undefined &&
			otherdata.nonFoodInstruction.fields.generalInfoDescription !== null &&
			genInfo !== null &&
			genInfo !== true
		) {
			let countryofOrigin =
				this.props.article.sys.locale === 'fr-CA'
					? 'Pays d’Origine'
					: otherdata.nonFoodInstruction.fields.generalInfoDescription.split('\n')[0];
			let madeInCountry = otherdata.nonFoodInstruction.fields.generalInfoDescription.split('\n')[1];
			generalInfo = (
				<div className='accor' id='accord'>
					<div className='header1' >
						{/* eslint-disable-next-line */}
						<a>{otherdata.nonFoodInstruction.fields.generalInfoTitle}</a>
					</div>
					<div className='body'>
						<div className='other-cont pl-20 pb-20'>
							<p className='paragraph'>
								<span id='country-of-origin'>{countryofOrigin}</span>
								<div id='country-of-origin-desc'>
									{madeInCountry}
								</div>
							</p>
						</div>
					</div>
				</div>
			);
		} else {
			generalInfo = null;
		}

		if (
			otherdata.nonFoodInstruction.fields.freeFromDescription === undefined ||
			otherdata.nonFoodInstruction.fields.freeFromDescription === 'Not Applicable'
		) {
			freeFromDes = null;
		} else {
			freeFromDes = (
				<div className='free pb-20'>
					<p id='free-from'>{otherdata.nonFoodInstruction.fields.freeFromDescription}</p>
				</div>
			);
		}

		let feat = otherdata.nonFoodInstruction.fields.featuresbenefitsDescription.split('\n');

		if (feat !== undefined) {
			//var featdata=feat.splice(0,feat.length-1);
			var claimdata = (
				<div className='accor' id='accord'>
					<div className='header1' onClick={this.hideClick}>
						{locale === 'en-US' || locale.toLowerCase() === 'en-ca' ? (
							// eslint-disable-next-line
							<a>Features & Benefits</a>
						) : (
							// eslint-disable-next-line
							<a>
								{locale === 'en-US' || locale.toLowerCase() === 'en-ca'
									? ''
									: otherdata.nonFoodInstruction.fields.claimsTitle}
							</a>
						)}
					</div>
					<div className='body'>
						<div className='other-cont'>
							<p className='blue-bg'>{otherdata.companyCommonData.fields.claimsStatement}</p>
							{freeFromDes}
							{locale !== 'en-US' ||
								(locale.toLowerCase() !== 'en-ca' && (
									<h2 className={
										otherdata.nonFoodInstruction.fields.featuresbenefitsTitle === 'Features & Benefits:' ? 'header4 hidden' : 'header4'
									} id='feature-benefit-header'>
										{otherdata.nonFoodInstruction.fields.featuresbenefitsTitle}
									</h2>
								))}
							<div className='paragraph pl-20 pb-20'>
								<ul id='features-benefits'>
									{feat.map((x, i) => (
										<li id='feature-benefit' key={i}>{x}</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			);
		}

		if (otherdata.nonFoodInstruction.fields.certificationDescription !== undefined) {
			var certificationDescr = (
				<div className='accor' id='accord' onClick={this.hideClick}>
					<div className='header1' onClick={this.hideClick}>
						{/* eslint-disable-next-line */}
						<a>{otherdata.nonFoodInstruction.fields.certificationTitle}</a>
					</div>
					<div className='body'>
						<div className='other-cont'>
							<p className='blue-bg'>{otherdata.companyCommonData.fields.certificationStatement}</p>
							{otherdata.nonFoodInstruction.fields.certificationDescription.map((c, i) => (
								<div className='certif-content pl-20 pb-20' key={i}>
									<h2 className='header4'>
										{c &&
											c.fields &&
											c.fields.certificationLogo &&
											c.fields.certificationLogo.fields &&
											c.fields.certificationLogo.fields.file && (
											<img
												src={c.fields.certificationLogo.fields.file.url}
												alt={c.fields.certificationLogo.fields.title}
											/>
										)}
										<span id='certificate-header'>{c.fields.certificationName}</span>
									</h2>
									<p className='paragraph' id='certificate-desc'>{c.fields.certificationDescription}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			);
		}

		if (otherdata.nonFoodInstruction.fields.storageInstructionDescription !== undefined) {
			var storageDesc = (
				<div className='certif-content pb-20'>
					<h2 className='header4' id='storage-instruction-header'>{otherdata.nonFoodInstruction.fields.storageInstructionTitle}</h2>
					<p className='paragraph' id='storage-instruction'>{otherdata.nonFoodInstruction.fields.storageInstructionDescription}</p>
					<br />
				</div>
			);
		}

		if (otherdata.nonFoodInstruction.fields.servingSuggestionDescription !== undefined) {
			var servingSugg = (
				<div className='certif-content pb-20'>
					<h2 className='header4' id='serving-suggestion-header'>{otherdata.nonFoodInstruction.fields.servingSuggestionTitle}</h2>
					<p className='paragraph' id='serving-suggestion'>{otherdata.nonFoodInstruction.fields.servingSuggestionDescription}</p>
					<br />
				</div>
			);
		}

		let suggestedUse;

		if (
			otherdata.nonFoodInstruction.fields.suggestedUseDescription === undefined ||
			otherdata.nonFoodInstruction.fields.suggestedUseDescription === 'Not Applicable' ||
			otherdata.nonFoodInstruction.fields.suggestedUseDescription === 'Not Available' ||
			otherdata.nonFoodInstruction.fields.suggestedUseDescription === 'NA' ||
			otherdata.nonFoodInstruction.fields.suggestedUseDescription === 'N/A'
		) {
			suggestedUse = null;
		} else {
			suggestedUse = (
				<div className='certif-content pb-20'>
					<h2 className='header4' id='suggested-use-header'>{otherdata.nonFoodInstruction.fields.suggestedUseTitle}</h2>
					<p className='paragraph' id='suggested-use'>{otherdata.nonFoodInstruction.fields.suggestedUseDescription}</p>
					<br />
				</div>
			);
		}

		let usageData = otherdata.nonFoodInstruction.fields.usageInstructionDescription.split('\n');

		if (otherdata.nonFoodInstruction.fields.usageInstructionDescription !== undefined) {
			var usageInstr = (
				<div className='certif-content pb-20'>
					<h2 className='header4' id='usage-header'>{otherdata.nonFoodInstruction.fields.usageInstructionTitle}</h2>
					{usageData.map((y, i) => (
						<p className='paragraph' id='usage' key={i}>
							{y}
						</p>
					))}
				</div>
			);
		}

		if (
			storageDesc !== undefined ||
			servingSugg !== undefined ||
			suggestedUse !== undefined ||
			usageInstr !== undefined
		) {
			var productInstruc = (
				<div className='accor' id='accord'>
					<div className='header1' onClick={this.hideClick}>
						{/* eslint-disable-next-line */}
						<a>{otherdata.nonFoodInstruction.fields.productInstructionsHeading}</a>
					</div>
					<div className='body'>
						<div className='other-cont pl-20'>
							{storageDesc}

							{servingSugg}

							{suggestedUse}

							{usageInstr}
						</div>
					</div>
				</div>
			);
		}

		let warning = otherdata.nonFoodInstruction.fields.warningDescription.split('\n');
		let warningDes;

		if (warning !== undefined) {
			warningDes = (
				<div className='certif-content'>
					{warning.map((y, i) => (
						<p className='paragraph' id='human-health' key={i}>
							{y}
						</p>
					))}
				</div>
			);
		} else {
			warningDes = (
				<div className='certif-content'>
					<p className='paragraph' id='human-health'>{otherdata.nonFoodInstruction.fields.warningDescription}</p>
				</div>
			);
		}

		if (otherdata.nonFoodInstruction.fields.warningDescription !== undefined ||
			otherdata.sdsPdf !== undefined) {
			console.log(otherdata);
			var healthsafety = (
				<div className='accor' id='accord'>
					<div className='header1' onClick={this.hideClick}>
						{locale === 'en-US' ? (
							// eslint-disable-next-line
							<a>Health & Safety</a>
						) : (
							//  eslint-disable-next-line
							<a>{otherdata.nonFoodInstruction.fields.warningTitle}</a>
						)}
					</div>
					<div className='body'>
						{otherdata.nonFoodInstruction.fields.warningDescription !== undefined ?
							<div className='other-cont pl-20 pb-20'>{warningDes}</div> : null}
						{otherdata.sdsPdf !== undefined ?
							otherdata.sdsPdf.map((pdf, key) => {
								return <p className='paragraph'
									id='sds'
									key={key}>
									<a
										onClick={(e) => {
											e.preventDefault();
											this.toggle(
												pdf.fields.file.url
											);
										}}>
										Click Here For Safety Data Sheet
									</a>
								</p>;
							}) : null}
					</div>
				</div>
			);
		}

		let panelData = otherdata.panel;

		let health;
		let ProductInstr;

		if (panelData === 'na' || panelData === undefined || panelData === 'N/A') {
			health = null;
		} else {
			health = healthsafety;
		}

		if (panelData === 'na' || panelData === undefined || panelData === 'N/A') {
			ProductInstr = null;
		} else {
			ProductInstr = productInstruc;
		}

		return (
			<div className='content-info'>
				<div className='container-fluid'>
					<Row>
						<div className='accord-content'>
							<Modal
								isOpen={this.state.modal}
								toggle={this.toggle}
								className='center-align pdf'
							>
								<ModalHeader toggle={this.toggle}>
									Safety Data Sheets
								</ModalHeader>
								<ModalBody>
									{
										// <object width='800' height='500' data={this.state.modalLink} />
										// eslint-disable-next-line
										<iframe
											id='pdfviewer'
											src={this.state.modalLink}
											frameborder='0'
											width='800'
											height='500'
										/>
									}
								</ModalBody>
							</Modal>
							<Col sm='12'>
								<Accordion>
									{claimdata}

									{certificationDescr}

									{generalInfo}

									{health}

									{ProductInstr}

									{locale === 'en-US' || locale.toLowerCase() === 'en-ca' ? '' : sustainability}
								</Accordion>
							</Col>
						</div>
					</Row>
				</div>
			</div>
		);
	}
}
