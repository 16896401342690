/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';

export default class Supplement extends React.Component {
	handleChange = (select) => {
		if (select.target.value === '0') {
			document.getElementById('servingsize-2').style.display = 'none';
			document.getElementById('servingsize-1').style.display = 'block';
		}
		if (select.target.value === '1') {
			document.getElementById('servingsize-2').style.display = 'block';
			document.getElementById('servingsize-1').style.display = 'none';
		}
	};

	render() {
		let inneranything;
		let subinneranything;
		let servingsize;
		let selectServing;
		let suppVals;
		let suppValues;
		let suppVal;
		let suppValss;
		const nutritiondata = this.props.article.fields.nutritionsupplementFact;
		let footsupplement = nutritiondata.fields.nutitionsupplementStatement.split('\n');
		let firstIndent;
		let secondIndent;
		let secondIndent2;
		let thirdIndent;

		if (nutritiondata.fields.servingSizeDesc2 !== undefined) {
			selectServing = (
				<p className='select-servingsize'>
					<span className='value'>
						<select onChange={this.handleChange}>
							<option value='0'>{nutritiondata.fields.servingSizeDesc}</option>
							<option value='1'>{nutritiondata.fields.servingSizeDesc2}</option>
						</select>
					</span>
				</p>
			);
		} else {
			selectServing = null;
		}

		if (nutritiondata.fields.servingSizeDesc2 !== undefined) {
			servingsize = (
				<div id='servingsize-2'>
					<header className='header-part'>
						<h1 className='main-title'>{nutritiondata.fields.factTitle}</h1>
						<p className='servingcontent'>
							<span className='title bold'>{nutritiondata.fields.servingSizeTitle}</span>
							<span className='value right'>{nutritiondata.fields.servingSizeDesc2}</span>
						</p>
					</header>
					<p className='servingcontent'>
						<span className='title bold'>{nutritiondata.fields.servingsPerContainerTitle2}</span>
						<span className='value right'>{nutritiondata.fields.servingsPerContainerDesc2}</span>
					</p>
					<table className='main-table'>
						<thead>
							<tr>
								<th className='amountPerServingText'>
									<span className='bold'>{nutritiondata.fields.amountPerServingText}</span>
									<span className='percentageDailyValueText bold right'>
										{nutritiondata.fields.percentageDailyValueText2}
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className='multivalues'>
								<td className='caloris'>
									<p className='subvalue'>
										<span className='ctext bold'>{nutritiondata.fields.claoriesTitle2}</span>
										<span className='cvalue'>{nutritiondata.fields.claoriesValue2}</span>
									</p>
								</td>
							</tr>
							{nutritiondata.fields.nutrientsupplement.map((x, i) => {
								if (x.fields.nutrientsupplement !== undefined) {
									inneranything = x.fields.nutrientsupplement.map((y, i) => {
										if (y.fields.nutrientsupplement !== undefined) {
											subinneranything = y.fields.nutrientsupplement.map((z, i) => {
												if (z.fields.nutrientsupplementValue2 === '#') {
													thirdIndent = '';
												} else {
													thirdIndent = z.fields.nutrientsupplementValue2;
												}

												return (
													<p key={i}>
														<span className='twoindent'>
															{z.fields.nutrientsupplementName2}
														</span>
														<span>{thirdIndent}</span>
														<span className='percentage bold right'>
															{z.fields.nutrientsupplementPercentageValue2}
														</span>
													</p>
												);
											});

											if (y.fields.nutrientsupplementValue2 === '#') {
												secondIndent = '';
											} else {
												secondIndent = y.fields.nutrientsupplementValue2;
											}

											return (
												<div key={i}>
													<p className='subvalue indent'>
														<span className='indent'>
															{y.fields.nutrientsupplementName2}
														</span>
														<span>{secondIndent}</span>
														<span className='percentage bold  right'>
															{y.fields.nutrientsupplementPercentageValue2}
														</span>
													</p>
													<div className='inner'>
														<span className=''>{subinneranything}</span>
													</div>
												</div>
											);
										} else if (y.fields.nutrientsupplementValue2 === '#') {
											secondIndent2 = '';
										} else {
											secondIndent2 = y.fields.nutrientsupplementValue2;
										}

										return (
											<div key={i}>
												<p>
													<span className='indent'>{y.fields.nutrientsupplementName2}</span>
													<span>{secondIndent2}</span>
													<span className='percentage bold right'>
														{y.fields.nutrientsupplementPercentageValue2}
													</span>
												</p>
											</div>
										);
									});

									if (x.fields.nutrientsupplementValue2 === '#') {
										firstIndent = '';
									} else {
										firstIndent = x.fields.nutrientsupplementValue2;
									}
									let firstIndentPercent;
									if (x.fields.nutrientsupplementPercentageValue2 === '#') {
										firstIndentPercent = '';
									} else {
										firstIndentPercent = x.fields.nutrientsupplementPercentageValue2;
									}

									return (
										<tr className='multivalues' key={i}>
											<td>
												<p className='subvalue'>
													<span className='bold'>{x.fields.nutrientsupplementName2}</span>
													<span>{firstIndent}</span>
													<span className='percentage bold right'>{firstIndentPercent}</span>
												</p>

												{inneranything}
											</td>
										</tr>
									);
								} else {
									if (x.fields.nutrientsupplementValue2 === '#') {
										firstIndent = '';
									} else {
										firstIndent = x.fields.nutrientsupplementValue2;
									}

									let secondIndentPercent;
									if (x.fields.nutrientsupplementPercentageValue2 === '#') {
										secondIndentPercent = '';
									} else {
										secondIndentPercent = x.fields.nutrientsupplementPercentageValue2;
									}

									return (
										<tr key={i}>
											<td>
												<p className='mainvaule'>
													<span className='bold'>{x.fields.nutrientsupplementName2}</span>
													<span>{firstIndent}</span>
													<span className='percentage bold right'>{secondIndentPercent}</span>
												</p>
											</td>
										</tr>
									);
								}
							})}
						</tbody>
					</table>
				</div>
			);
		} else {
			servingsize = null;
		}
		return (
			<div className='maincontainer supplement'>
				<div>{selectServing}</div>
				<section className='table-section '>
					<div id='servingsize-1'>
						<header className='header-part'>
							<h1 className='main-title'>{nutritiondata.fields.factTitle}</h1>
							<p className='servingcontent'>
								<span className='title bold'>{nutritiondata.fields.servingSizeTitle}</span>
								<span className='value right'>{nutritiondata.fields.servingSizeDesc}</span>
							</p>
						</header>
						<p className='servingcontent'>
							<span className='title bold'>{nutritiondata.fields.servingsPerContainerTitle}</span>
							<span className='value right'>{nutritiondata.fields.servingsPerContainerDesc}</span>
						</p>
						<table className='main-table'>
							<thead>
								<tr>
									<th className='amountPerServingText'>
										<span className='bold'>{nutritiondata.fields.amountPerServingText}</span>
										<span className='percentageDailyValueText bold right'>
											{nutritiondata.fields.percentageDailyValueText}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className='multivalues'>
									<td className='caloris'>
										<p className='subvalue'>
											<span className='ctext bold'>{nutritiondata.fields.claoriesTitle}</span>
											<span className='cvalue'>{nutritiondata.fields.claoriesValue}</span>
										</p>
									</td>
								</tr>
								{nutritiondata.fields.nutrientsupplement !== undefined
									? nutritiondata.fields.nutrientsupplement.map((x, i) => {
										if (x.fields.nutrientsupplement !== undefined) {
											inneranything = x.fields.nutrientsupplement.map((y, i) => {
												if (y.fields.nutrientsupplement !== undefined) {
													subinneranything = y.fields.nutrientsupplement.map((z, i) => {
														if (z.fields.nutrientsupplementValue === '#') {
															suppVal = '';
														} else {
															suppVal = z.fields.nutrientsupplementValue;
														}
														return (
															<p key={i}>
																<span className='twoindent'>
																	{z.fields.nutrientsupplementName}
																</span>
																<span>{suppVal}</span>
																<span className='percentage bold right'>
																	{z.fields.nutrientsupplementPercentageValue}
																</span>
															</p>
														);
													});
													if (y.fields.nutrientsupplementValue === '#') {
														suppVals = '';
													} else {
														suppVals = y.fields.nutrientsupplementValue;
													}
													return (
														<div key={i}>
															<p className='subvalue'>
																<span className='indent'>
																	{y.fields.nutrientsupplementName}
																</span>
																<span>{suppVals}</span>
																<span className='percentage bold  right'>
																	{y.fields.nutrientsupplementPercentageValue}
																</span>
															</p>
															<div className='inner'>
																<span className=''>{subinneranything}</span>
															</div>
														</div>
													);
												} else if (y.fields.nutrientsupplementValue === '#') {
													suppValues = '';
												} else {
													suppValues = y.fields.nutrientsupplementValue;
												}
												return (
													<div key={i}>
														<p>
															<span className='indent'>
																{y.fields.nutrientsupplementName}
															</span>
															<span>{suppValues}</span>
															<span className='percentage bold right'>
																{y.fields.nutrientsupplementPercentageValue}
															</span>
														</p>
													</div>
												);
											});
											if (x.fields.nutrientsupplementValue === '#') {
												suppValss = '';
											} else {
												suppValss = x.fields.nutrientsupplementValue;
											}
											return (
												<tr className='multivalues' key={i}>
													<td>
														<p className='subvalue'>
															<span className='bold'>
																{x.fields.nutrientsupplementName}
															</span>
															<span>{suppValss}</span>
															<span className='percentage bold right'>
																{x.fields.nutrientsupplementPercentageValue}
															</span>
														</p>

														{inneranything}
													</td>
												</tr>
											);
										} else {
											let suppVales;
											if (x.fields.nutrientsupplementValue === '#') {
												suppVales = '';
											} else {
												suppVales = x.fields.nutrientsupplementValue;
											}
											return (
												<tr key={i}>
													<td>
														<p className='mainvaule'>
															<span className='bold'>
																{x.fields.nutrientsupplementName}
															</span>
															<span>{suppVales}</span>
															<span className='percentage bold right'>
																{x.fields.nutrientsupplementPercentageValue}
															</span>
														</p>
													</td>
												</tr>
											);
										}
									  })
									: ''}
							</tbody>
						</table>
					</div>

					{/* 2nd Value */}

					{servingsize}

					<div className='footer-text'>
						{footsupplement.map((y, i) => (
							<p className='detailtext' key={i}>
								{y}
							</p>
						))}
					</div>
				</section>
			</div>
		);
	}
}
