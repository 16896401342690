import React from 'react';
//import Header from './components/Header/header';

export default class E500 extends React.Component {
	render() {
		const errordata = this.props.errortemp1;

		return (
			<div>
				<header className='header'>
					<div className='logo'>
						<img
							className='img-logo'
							src={
								errordata.fields.smartLabelImage.fields.file.url
							}
							alt=''
						/>
					</div>
				</header>
				<div className='error-content'>
					<div className='e-content'>
						<h1>{errordata.fields.errorTitle500}</h1>
						<h2>{errordata.fields.errorDescription500}</h2>
					</div>
				</div>

				<div className='efooter'>
					<p className='efooter-text'>
						{errordata.fields.footerText}
					</p>
				</div>
			</div>
		);
	}
}
