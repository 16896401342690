import React from 'react';
//import Header from './components/Header/header';
import anchorme from 'anchorme';

export default class E404 extends React.Component {
	render() {
		const errordata = this.props.errortemp1;
		let errorIng = errordata.fields.errorDescription404;
		let errorIngLink = anchorme(errorIng, {
			attributes: [
				{
					name: 'target',
					value: '_blank',
				},
				{
					name: 'rel',
					value: 'noreferrer noopener',
				},
			],
		});

		return (
			<div>
				{/* <header className='header'>
					<div className='logo'>
						<img className='img-logo' src={errordata.fields.smartLabelImage.fields.file.url} alt=''/>  
					</div>
				</header> */}
				<div className='error-content'>
					<div className='e-content'>
						<img src={errordata.fields.errorImage.fields.file.url} alt='error' />
						<h2 dangerouslySetInnerHTML={{ __html: errorIngLink }}></h2>
					</div>
				</div>

				{/* <div className='efooter'>
                    
					<p className='efooter-text'>{errordata.fields.footerText}</p>
                   
				</div> */}
			</div>
		);
	}
}
