import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

export default class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			modalLink: '',
		};
		this.toggle = this.toggle.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}

	toggle(url) {
		this.setState({
			modal: !this.state.modal,
			modalLink: url,
		});
	}
	closeModal() {
		this.setState({
			modal: !this.state.modal
		});
	}

	handleClick = (e) => {
		e.preventDefault();
		window.Optanon.ToggleInfoDisplay();
	};

	render() {
		const footerdata = this.props.article.fields;
		const locale = this.props.article.sys.locale.toLowerCase();
		const footersys = this.props.article.sys;
		const lastUpdDate = footersys.updatedAt;
		const updatedDate = moment(lastUpdDate).format('YYYY-MM-DD');
		const BrandText = footerdata.brand.fields.brandName;
		const footerText = footerdata.companyCommonData.fields.distributedBy;
		const updatedBy = `${footerText.split('BRAND')[0]}BRAND`
			.replace(/BRAND/g, BrandText)
			.replace(/DATE/g, updatedDate);
		let distributedBy;

		if (locale === 'en-us') {
			if (BrandText.toLowerCase().includes('burts bees')) {
				distributedBy = 'DISTR. BY SUNFLOWER DISTRIBUTING LLC, CINCINNATI, OH 45202';
			} else if (BrandText.toLowerCase().includes('native')) {
				distributedBy = 'Distr. by Native San Francisco, CA 94111';
			} else distributedBy = 'DISTR. BY PROCTER & GAMBLE, CINCINNATI, OHIO 45202';
		} else if (locale === 'en-ca') {
			if (BrandText.toLowerCase().includes('burts bees')) {
				distributedBy = 'Imported by NPN NATURALS INC, Toronto, ON M5N 2T6 on behalf of D-TERRA SOLUTIONS LLC.';
			} else if (BrandText.toLowerCase().includes('head & shoulders')) {
				distributedBy = 'IMPORTED FOR PROCTER & GAMBLE INC., TORONTO, ON M5W 1C5';
			} else if (BrandText.toLowerCase().includes('vicks')) {
				distributedBy = 'DISTR. BY PROCTER & GAMBLE INC., TORONTO, ON M5W 1C5';
			} else distributedBy = 'DISTR. BY PROCTER & GAMBLE, TORONTO, ONTARIO M5W 1C5';
		} else {
			if (BrandText.toLowerCase().includes('head & shoulders')) {
				distributedBy = 'IMPORTÉ POUR PROCTER & GAMBLE INC., TORONTO, ON M5W 1C5';
			} else if (BrandText.toLowerCase().includes('vicks')) {
				distributedBy = 'DISTR. PAR PROCTER & GAMBLE INC., TORONTO, ON M5W 1C5';
			} else distributedBy = 'DISTR. PAR PROCTER & GAMBLE, TORONTO, ONTARIO M5W 1C5';
		}

		return (
			<footer>
				<div className='footer-content'>
					<p>{updatedBy}</p>
					<p>{distributedBy}</p>

					<ul className='privacy'>
						{locale === 'en-us' ? (
							<React.Fragment>
								<li className='pri'>
									<a href={'https://privacypolicy.pg.com/en/#statePrivacyNotice'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('https://privacypolicy.pg.com/en/#statePrivacyNotice', e);
										}}
										className='do-not-sell'>Privacy</a>
								</li>
								<li className='pri'>
									<a href={'https://privacypolicy.pg.com/CHD-en'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('https://privacypolicy.pg.com/CHD-en', e);
										}}
										className='do-not-sell'>Consumer Health Data Privacy Policy</a>
								</li>
								<li className='pri'>
									<a href={'http://www.pg.com/en_US/terms_conditions/index.shtml'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('http://www.pg.com/en_US/terms_conditions/index.shtml', e);
										}}
										className='do-not-sell'>Terms and Conditions</a>
								</li>
								<li className='pri'>
									<a href={'https://preferencecenter.pg.com/en-us'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('https://preferencecenter.pg.com/en-us', e);
										}}
										className='adchoicesus'>Your Privacy Choices</a>
								</li>

								<li className='pri'>
									<a href={'https://smartlabel.org/contact-us/'}>
										{footerdata.companyCommonData.fields.feedbackForSmartLabelTitle}
									</a>
								</li>

								<li className='pri'>
									<a href={'javascript:void(0)'}
										onClick={(e) => {
											e.preventDefault();
											window.Optanon.ToggleInfoDisplay();
										}}
										className='do-not-sell'>Do Not Sell or Share My Personal Information / Opt-Out of Targeted Advertising</a>
								</li>
								<li>
									<a className='do-not-sell'>NOTICE: We may sell your sensitive personal data.</a>
								</li>
							</React.Fragment>
						) : (locale === 'en-ca') ? (
							<React.Fragment>
								<li className='pri'>
									<a href={'http://www.pg.com/privacy/english/privacy_statement.shtml'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('http://www.pg.com/privacy/english/privacy_statement.shtml', e);
										}}
										className='do-not-sell'>Privacy</a>
								</li>
								<li className='pri'>
									<a href={'http://www.pg.com/en_US/terms_conditions/index.shtml'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('http://www.pg.com/en_US/terms_conditions/index.shtml', e);
										}}
										className='do-not-sell'>Terms and Conditions</a>
								</li>
								<li className='pri'>
									<a href={'https://preferencecenter.pg.com/en-ca/'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('https://preferencecenter.pg.com/en-ca/', e);
										}}
										className='do-not-sell'>My Data</a>
								</li>
								<li className='pri'>
									<a href={'https://smartlabel.org/contact-us/'}>
										{footerdata.companyCommonData.fields.feedbackForSmartLabelTitle}
									</a>
								</li>

								<li>
									<a href={'javascript:void(0)'}
										onClick={(e) => {
											e.preventDefault();
											window.Optanon.ToggleInfoDisplay();
										}}
										className='do-not-sell'>Opt Out of Targeted Advertising</a>
								</li>
								<li className='pri'>
									<a
										href={'https://privacypolicy.pg.com/en/#iba'}
										className='adchoices'>
										AdChoices
									</a>
								</li>
							</React.Fragment>
						) : (locale === 'fr-ca') ? (
							<React.Fragment>
								<li className='pri'>
									<a href={'http://www.pg.com/privacy/frenchcanadian/privacy_statement.shtml'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('http://www.pg.com/privacy/frenchcanadian/privacy_statement.shtml', e);
										}}
										className='do-not-sell'>Notification de Confidentialite										</a>
								</li>
								<li className='pri'>
									<a href={'http://www.pg.com/fr_CA/terms_conditions/index.shtml'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('http://www.pg.com/fr_CA/terms_conditions/index.shtml', e);
										}}
										className='do-not-sell'>Conditions d’utilisation										</a>
								</li>
								<li className='pri'>
									<a href={'https://preferencecenter.pg.com/fr-ca/'}
										onClick={(e) => {
											e.preventDefault();
											this.toggle('https://preferencecenter.pg.com/fr-ca/', e);
										}}
										className='do-not-sell'>Mes données										</a>
								</li>

								<li className='pri'>
									<a href={'https://smartlabel.org/contact-us/'}>
										{footerdata.companyCommonData.fields.feedbackForSmartLabelTitle}
									</a>
								</li>
								<li>
									<a href={'javascript:void(0)'}
										onClick={(e) => {
											e.preventDefault();
											window.Optanon.ToggleInfoDisplay();
										}}
										className='do-not-sell'>Me désinscrire de la publicité ciblée</a>
								</li>
								<li className='pri'>
									<a
										href={'https://privacypolicy.pg.com/frca/#iba'}
										className='adchoices'>
										Choix de pub
									</a>
								</li>
							</React.Fragment>
						) : (
							<li>
								<a href={'mailto: feedback@smartlabelcanada.org'}>
									{footerdata.companyCommonData.fields.feedbackForSmartLabelTitle}
								</a>
							</li>
						)}
					</ul>
				</div>

				<Modal isOpen={this.state.modal} toggle={this.toggle} className='center-align'>
					<ModalHeader toggle={this.toggle}>
						{footerdata.companyCommonData.fields.leavingSmartLabelTitle}
					</ModalHeader>
					<ModalBody>{footerdata.companyCommonData.fields.leavingSmartLabelDescription}</ModalBody>
					<ModalFooter>
						<Button color='secondary' onClick={this.toggle}>
							Cancel
						</Button>
						<a rel='noopener noreferrer' href={this.state.modalLink} target="_blank" onClick={this.closeModal}>
							<Button color='primary' onClick={this.closeModal}>
								Continue
							</Button>
						</a>
					</ModalFooter>
				</Modal>
			</footer>
		);
	}
}
