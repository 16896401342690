import React from 'react';
import { Row} from 'reactstrap';

export default class DrugFact extends React.Component{
    
	drugClick(){
		document.getElementById('all-drug-fact').style.display = 'block';
		document.getElementsByClassName('drug-image')[0].style.display='block';
		document.getElementsByClassName('img-logo')[0].style.visibility='hidden';
		document.getElementById('tab').style.display='none';
		document.getElementById('ingredients').style.display='none';
		document.getElementsByClassName('drugFact')[0].style.display='none';
		document.getElementsByClassName('footer-content')[0].style.display='none';
		document.getElementsByClassName('products')[0].style.display='none';
	}

	render(){
		const drugData = this.props.article.fields;
		var drugField = drugData.drug;
		if(drugField !== undefined){
			var data = drugField?drugField.toUpperCase():undefined;
		}
		if(data === 'YES'|| data === 'Y'){
			var drug = (
				<Row>
					<div className="drugFact" id='drug-fact-tab'>
						<div className="blue-bg" onClick={this.drugClick}>
							<p className="header1">
								{drugData.drugFactImage.fields.title} 
								<i className="fa fa-chevron-right"></i></p>
						</div>
					</div>
					<div className="drug-image">
						<img id='drug-fact-img' src={drugData.drugFactImage.fields.file.url} alt={drugData.drugFactImage.fields.file.url}/>
					</div>
				</Row>
			);
		}else{
			drug=null;
		}


		return(
			<div>
				{drug}
			</div>
		);
	}
}