import React from 'react';

const displayNutrientData = (isSingle, arrID, nutrients, text = null) => {
	return nutrients.map((nut, id) => {
		if (text) {
			nut.text = text[id];
		}
		const indent = (nut.title.match(/>/g) || []).length;
		const padding = indent * 15;
		const value = isSingle ? nut.data.value : nut.data[arrID].value;
		const dv = isSingle ? nut.data.dv : nut.data[arrID].dv;

		return (
			<div key={id} className='nutrients'>
				<div className='nutrient'>
					<span className='flexx' style={{ paddingLeft: `${padding}px` }}>
						<span>
							<span className={indent === 0 ? 'bold' : undefined}>
								{nut.title.includes('Trans') && (
									<span className='oblique'>{nut.title.match(/Trans/)[0]}</span>
								)}
								<span>{nut.title.replace(/>/g, '').replace(/Trans/g, '')} &nbsp;</span>
							</span>
							{value}
						</span>
						{dv && <span className='bold'>{dv}</span>}
					</span>
					{nut.text && <span className='additional-text'>{nut.text}</span>}
				</div>
			</div>
		);
	});
};

const sections = (classname, title, value, id) => {
	return (
		<div className={classname} key={id}>
			<span className='bold'>{title} &nbsp;</span>
			<span className={title.includes('Amount') ? 'percentage' : undefined}>{value}</span>
		</div>
	);
};

const SupplementTable = (props) => {
	const { supplement, arrID, isSingle } = props;
	const { ServingSize, ServingsPerContainer, Calories } = supplement;
	const ServingSizeData = ServingSize
		? isSingle
			? ServingSize.data.value
			: ServingSize.data[arrID].value
		: undefined;
	const ServingsPerContainerData = ServingsPerContainer
		? isSingle
			? ServingsPerContainer.data.value
			: ServingsPerContainer.data[arrID].value
		: undefined;
	const CaloriesData = Calories ? (isSingle ? Calories.data.value : Calories.data[arrID].value) : undefined;
	const toDisplay = [
		ServingSize && {
			className: 'serving',
			title: ServingSize.title,
			value: ServingSizeData,
		},
		ServingsPerContainer && {
			className: 'serving',
			title: ServingsPerContainer.title,
			value: ServingsPerContainerData,
		},
		supplement.AmountPerServing &&
			supplement.PercentageDailyValue && {
			className: 'amount',
			title: supplement.AmountPerServing,
			value:
					typeof supplement.PercentageDailyValue === 'string'
						? supplement.PercentageDailyValue
						: supplement.PercentageDailyValue[arrID],
		},
		Calories && {
			className: 'calories',
			title: Calories.title,
			value: CaloriesData,
		},
	];

	return (
		<section className='supplement'>
			{arrID && (
				<section className='table'>
					<header>
						<h1 className='table-header'>{supplement.header}</h1>
					</header>
					{toDisplay.filter((i) => i).map((sec, id) => sections(sec.className, sec.title, sec.value, id))}
					{supplement.Nutrients && displayNutrientData(isSingle, arrID, supplement.Nutrients)}
					{supplement.AdditionalText && <div className='additional' />}
					{supplement.AdditionalNutrients &&
						displayNutrientData(isSingle, arrID, supplement.AdditionalNutrients, supplement.AdditionalText)}
					{supplement.AdditionalText && <div className='additional' />}
					<div className='subscripts'>
						{supplement.subScripts.map((i, id2) => (
							<div key={id2}>{i.replace(/\u00a0/g, ' ')}</div>
						))}
					</div>
				</section>
			)}
		</section>
	);
};

export default SupplementTable;
