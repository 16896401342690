/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// eslint-disable-next-line no-unused-vars
import { debug } from 'util';

export default class Company extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			modalLink: '',
			isLocal: '',
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle(url) {
		this.setState({
			modal: !this.state.modal,
			modalLink: url,
		});
	}

	state = { showing: false };

	render() {
		const companydata = this.props.article.fields;

		const { showing } = this.state;
		var call = companydata.brand.fields.callUs;
		var callopt = 'tel:' + call;
		const locale = this.props.article.sys.locale;

		let BrandText = companydata.brand.fields.brandName;
		const isExcludedBrand =
			BrandText === 'Burts Bees' || BrandText === 'All Good' || BrandText === 'Zevo Instant Action Spray';

		const PGforGood = [
			{
				url:
					locale === 'fr-CA'
						? 'https://pg.ca/fr-ca/ingredients-et-composition-des-produits-pg/'
						: 'https://us.pg.com/ingredients/',
				title:
					locale === 'fr-CA'
						? 'Ingrédients pour le bien Procter & Gamble'
						: 'Procter & Gamble Ingredients for Good',
			},
			{
				url: locale === 'fr-CA' ? 'https://www.pgeveryday.ca/fr' : 'https://www.pggoodeveryday.com/',
				title: locale === 'fr-CA' ? 'Œuvrer pour le bien Procter & Gamble' : 'Procter & Gamble Force for Good',
			},
		];
		const social = [
			{
				url: companydata.brand.fields.youtube,
				title: companydata.companyCommonData.fields.youTubeTitle,
			},
			{
				url: companydata.brand.fields.facebook,
				title: companydata.companyCommonData.fields.facebookTitle,
			},
		];
		const newURLChanges = PGforGood.map((list, id) => (
			<div key={id}>
				{companydata.brand.fields.aboutBrand !== undefined ? (
					<li>
						<a
							className='header4'
							href={list.url}
							onClick={(e) => {
								e.preventDefault();
								this.toggle(list.url);
							}}
						>
							{list.title}
							<i className='fa fa-external-link' aria-hidden='true'></i>
						</a>
					</li>
				) : null}
			</div>
		));
		const socialLinks = social.map((slink, id) => {
			return slink.url ? (
				<div key={id}>
					<li>
						<a
							className='header4'
							href={slink.url}
							onClick={(e) => {
								e.preventDefault();
								this.toggle(slink.url);
							}}
						>
							{companydata.brand.fields.brandName}&nbsp;
							{slink.title}
							<i className='fa fa-external-link' aria-hidden='true'></i>
						</a>
					</li>
				</div>
			) : null;
		});
		const pgSustainabilityCode = () => {
			return companydata.companyCommonData.fields.pgSustainability !== undefined ? (
				<li>
					<a
						className='header4'
						href={companydata.companyCommonData.fields.pgSustainability}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(companydata.companyCommonData.fields.pgSustainability);
						}}
					>
						{companydata.companyCommonData.fields.sustainabilityTitle}
						<i className='fa fa-external-link' aria-hidden='true'></i>
					</a>
				</li>
			) : null;
		};

		const aboutUsCode = () => {
			return companydata.companyCommonData.fields.aboutPg !== undefined ? (
				<li>
					<a
						className='header4'
						href={companydata.companyCommonData.fields.aboutPg}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(companydata.companyCommonData.fields.aboutPg);
						}}
					>
						{companydata.brand.fields.aboutTitle} Procter & Gamble
						<i className='fa fa-external-link' aria-hidden='true'></i>
					</a>
				</li>
			) : null;
		};

		const justCACode = () => (
			<div className='icons call' onClick={this.onClick}>
				<i
					className='fa fa-phone'
					onClick={() => this.setState({ showing: !showing })}
					style={{ display: showing ? 'none' : 'block' }}
				></i>
				<span
					style={{ display: showing ? 'block' : 'none' }}
					onClick={() => this.setState({ showing: !showing })}
				>
					{companydata.brand.fields.callUs}
				</span>
				<p className='header1' onClick={() => this.setState({ showing: !showing })}>
					{companydata.brand.fields.callUsTitle}
				</p>
			</div>
		);

		let aboutpg;
		let contact;
		let justca;
		let contactURL = 'https://www.burtsbees.com/content/contact-us/contact-us.html#XSzdt5lWWiBCgh0d.99';
		let JustContactURL = 'https://pgconsumersupport.secure.force.com/carehub/?country=US&language=EN&brand=just';
		let Justcall = '(866) 606-6033';
		let nativeCallUs =
			companydata.brand.fields.contactUsUrl !== undefined ? companydata.brand.fields.contactUsUrl : null;

		if (BrandText === 'Burts Bees') {
			aboutpg = null;
			contact = (
				<div className='icons line-height'>
					<a
						href={contactURL}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(contactURL);
						}}
					>
						<i className='fa fa-envelope-o'></i>
						<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
					</a>
				</div>
			);
			justca = justCACode();
		} else if (BrandText.toLowerCase() === 'all good') {
			contact = (
				<React.Fragment>
					<div className='icons line-height'>
						<a
							href={companydata.brand.fields.contactUsAllGood}
							onClick={(e) => {
								e.preventDefault();
								this.toggle(companydata.brand.fields.contactUsAllGood);
							}}
						>
							<i className='fa fa-envelope-o'></i>
							<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
						</a>
					</div>
				</React.Fragment>
			);
			justca = justCACode();
		} else if (BrandText.toLowerCase() === 'zevo instant action spray') {
			contact = (
				<React.Fragment>
					<div className='icons line-height'>
						{/* eslint-disable-next-line */}
						<a>
							<i className='fa fa-envelope-o'></i>
							<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
						</a>
					</div>
				</React.Fragment>
			);
			justca = justCACode();
			aboutpg = (
				<div>
					{aboutUsCode()}
					{pgSustainabilityCode()}
				</div>
			);
		} else if (this.props.article.fields.brandName === 'All in ONEder') {
			contact = (
				<div className='icons line-height'>
					{/* eslint-disable-next-line */}
					<a
						onClick={() => {
							this.toggle(companydata.companyCommonData.fields.contactUs);
						}}
					>
						<i className='fa fa-envelope-o' />
						<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
					</a>
				</div>
			);
			justca = (
				<div className='icons line-height'>
					<a href={'mailto:' + companydata.brand.fields.callUs} rel='noopener noreferrer'>
						<i className='fa fa-envelope-o' />
						<p className='header1'>{companydata.brand.fields.callUsTitle}</p>
					</a>
				</div>
			);
			aboutpg = pgSustainabilityCode();
		} else if (BrandText === 'Just') {
			contact = (
				<div className='icons line-height'>
					<a
						href={JustContactURL}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(JustContactURL);
						}}
					>
						<i className='fa fa-envelope-o'></i>
						<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
					</a>
				</div>
			);
			justca = (
				<div className='icons call' onClick={this.onClick}>
					<i
						className='fa fa-phone'
						onClick={() => this.setState({ showing: !showing })}
						style={{ display: showing ? 'none' : 'block' }}
					></i>
					<span
						style={{ display: showing ? 'block' : 'none' }}
						onClick={() => this.setState({ showing: !showing })}
					>
						{Justcall}
					</span>
					<p className='header1' onClick={() => this.setState({ showing: !showing })}>
						{companydata.brand.fields.callUsTitle}
					</p>
				</div>
			);
			aboutpg = (
				<div>
					{aboutUsCode()}
					{pgSustainabilityCode()}
				</div>
			);
		} else if (BrandText === 'Native') {
			contact = (
				<div className='icons line-height'>
					<a
						href={nativeCallUs}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(nativeCallUs);
						}}
					>
						<i className='fa fa-envelope-o'></i>
						<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
					</a>
				</div>
			);
			justca = (
				<div className='icons call' onClick={this.onClick}>
					<i
						className='fa fa-envelope-o'
						onClick={() => this.setState({ showing: !showing })}
						style={{ display: showing ? 'none' : 'block' }}
					></i>
					<span
						style={{ display: showing ? 'block' : 'none' }}
						onClick={() => this.setState({ showing: !showing })}
					>
						{companydata.brand.fields.callUs}
					</span>
					<p className='header1' onClick={() => this.setState({ showing: !showing })}>
						Email Us
					</p>
				</div>
			);
			aboutpg = pgSustainabilityCode();
		} else {
			aboutpg = (
				<div>
					{aboutUsCode()}
					{pgSustainabilityCode()}
				</div>
			);
			contact = (
				<div className='icons line-height'>
					<a
						href={companydata.companyCommonData.fields.contactUs}
						onClick={(e) => {
							e.preventDefault();
							this.toggle(companydata.companyCommonData.fields.contactUs);
						}}
					>
						<i className='fa fa-envelope-o'></i>
						<p className='header1'>{companydata.companyCommonData.fields.contactUsTitle}</p>
					</a>
				</div>
			);
			justca = justCACode();
		}

		return (
			<div className='content-info'>
				<Container>
					<Row>
						<Col xs='6' sm='6'>
							{contact}
						</Col>
						<Col xs='6' sm='6'>
							{justca}
							<div className='icons-sp' onClick={this.onClick}>
								<a href={callopt} rel='noreferrer noopener'>
									<i className='fa fa-phone'></i>
									<p className='header1'>{companydata.brand.fields.callUsTitle}</p>
								</a>
							</div>
						</Col>
						<Col sm='12'>
							<ul className='company-list'>
								{companydata.brand.fields.aboutBrand !== undefined ? (
									<li>
										<a
											className='header4'
											href={companydata.brand.fields.aboutBrand}
											onClick={(e) => {
												e.preventDefault();
												this.toggle(companydata.brand.fields.aboutBrand);
											}}
										>
											{companydata.brand.fields.aboutTitle}
											&nbsp;
											{companydata.brand.fields.brandName}
											<i className='fa fa-external-link' aria-hidden='true'></i>
										</a>
									</li>
								) : null}

								{isExcludedBrand ? socialLinks : newURLChanges}
								{aboutpg}
							</ul>

							<Modal isOpen={this.state.modal} toggle={this.toggle} className='center-align'>
								<ModalHeader toggle={this.toggle}>
									{companydata.companyCommonData.fields.leavingSmartLabelTitle}
								</ModalHeader>
								<ModalBody>
									{companydata.companyCommonData.fields.leavingSmartLabelDescription}
								</ModalBody>
								<ModalFooter>
									<Button color='secondary' onClick={this.toggle}>
										Cancel
									</Button>
									<Button color='primary' href={this.state.modalLink}>
										Continue
									</Button>
								</ModalFooter>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
