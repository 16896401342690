/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import '../../scss/header.scss';
import axios from 'axios';

var GTIN = '';
var LOCAL = '';
let isInvalid = true;
var regex = RegExp('^([0-9]{14})(.html)((#[a-z]+)?)$');

export default class Header extends React.Component {
	constructor(props) {
		super(props);
		var path = window.location.pathname;
		var len = path.match(new RegExp('/', 'g')).length;
		var arr = path.split('/');
		if (len === 2) {
			if (regex.test(arr[2])) {
				isInvalid = false;
			}

			LOCAL = arr[1];
			GTIN = arr[2].split('.')[0];
		} else if (len === 1) {
			GTIN = arr[1].split('.')[0];
			if (regex.test(arr[1])) {
				isInvalid = false;
			}
		}
		this.state = {
			modal: false,
			modalLink: '',
			gtin: GTIN,
			articles: props.article || [],
			button: '',
		};
		this.toggle = this.toggle.bind(this);
	}

	componentDidMount() {
		if (this.props.local !== 'en-US') {
			axios
				.get(
					'https://az-na-smartlabel-prod-functionapp-api.pgcloud.com/api/getstatus?gtin=' +
						GTIN +
						'&locale=' +
						LOCAL
				)
				.then((responses) => {
					if (responses.data === 'True') {
						document.getElementById('lang-btn').style.display = 'block';
					} else {
						document.getElementById('lang-btn').style.display = 'none';
					}
				});
		}
	}

	toggle(url) {
		this.setState({
			modal: !this.state.modal,
			modalLink: url,
		});
	}

	backClick = () => {
		if (window.innerWidth < 767) {
			document.getElementsByClassName('ingredient-content')[0].style.display = 'none';
			document.getElementById('all-ingredient-list').style.display = 'none';
			document.getElementById('ingredient-list').style.display = 'block';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
		}
	};

	backtoFragrance = () => {
		if (window.innerWidth < 767) {
			document.getElementsByClassName('frag-ing')[0].style.display = 'block';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
			document.getElementById('all-ingredient-list').style.display = 'block';
		} else {
			document.getElementsByClassName('frag-ing')[0].style.display = 'block';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
			document.getElementById('all-ingredient-list').style.display = 'none';
		}
	};

	backImage = () => {
		document.getElementById('all-image-zoom').style.display = 'none';
		document.getElementsByClassName('products')[0].style.display = 'block';
		document.getElementsByClassName('zoomImage')[0].style.display = 'none';
		document.getElementsByClassName('img-logo')[0].style.visibility = 'visible';
		document.getElementsByClassName('tabs')[0].style.display = 'block';
		document.getElementsByClassName('footer-content')[0].style.display = 'block';
		document.getElementsByClassName('country-panel')[0].style.display = 'block';
	};

	drugImage = () => {
		document.getElementById('all-drug-fact').style.display = 'none';
		document.getElementsByClassName('drug-image')[0].style.display = 'none';
		document.getElementsByClassName('img-logo')[0].style.visibility = 'visible';
		document.getElementById('tab').style.display = 'block';
		document.getElementById('ingredients').style.display = 'block';
		document.getElementsByClassName('drugFact')[0].style.display = 'block';
		document.getElementsByClassName('footer-content')[0].style.display = 'block';
		document.getElementsByClassName('products')[0].style.display = 'block';
	};

	render() {
		const logodata = this.props.article.fields;
		let languagebtn;
		let urls = window.location.origin;
		let url = window.location.pathname;
		let urlval = url.substr(0, url.lastIndexOf('/', url.lastIndexOf('/')));

		let urllocal = urlval.replace('/', '');
		// const pathUrl = this.state.gtin + '.html';

		if (urllocal === 'en-CA' || urllocal === 'en-ca') {
			languagebtn = (
				<div className='country-container' id='lang-btn'>
					<a href={`${urls}/fr-CA/${this.state.gtin}.html`}>français</a>
				</div>
			);
		}

		if (urllocal === 'fr-CA' || urllocal === 'fr-ca') {
			languagebtn = (
				<div className='country-container' id='lang-btn'>
					<a href={`${urls}/en-CA/${this.state.gtin}.html`}>English</a>
				</div>
			);
		}

		return (
			<header className='header'>
				<div className='logo'>
					<img
						className='img-logo'
						src='https://images.ctfassets.net/p9x6nkt33m4y/6OzYX983cGLrnetlXfaSeO/bfa6c6b5cecc624ec2738d899ed8b992/SL-Logo-2.png'
						// src={logodata.companyCommonData.fields.smartLabelLogo.fields.file.url}
						alt=''
					/>
					<div className='back' id='all-ingredient-list' onClick={() => this.backClick()}>
						<i className='fa fa-chevron-left fa-5' aria-hidden='true' />
					</div>
					<div className='back' id='all-fragrance' onClick={this.backtoFragrance}>
						<i className='fa fa-chevron-left fa-5' aria-hidden='true' />
					</div>
					<div className='back' id='all-image-zoom' onClick={this.backImage}>
						<i className='fa fa-chevron-left fa-5' aria-hidden='true' />
					</div>
					<div className='back' id='all-drug-fact' onClick={this.drugImage}>
						<i className='fa fa-chevron-left fa-5' aria-hidden='true' />
					</div>
				</div>
				<div className='country-panel'>{languagebtn}</div>
			</header>
		);
	}
}
