import React from 'react';
import ReactDOM from 'react-dom';
import Main from './app';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/styles.scss';
import 'font-awesome/css/font-awesome.min.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
ReactDOM.render(
	<BrowserRouter>
		<Main />
	</BrowserRouter>,
	document.getElementById('root')
);
serviceWorker.register();

