/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
//import webfont from 'webfont';
import Supplement from './supplement';

export default class Nutrition extends React.Component {
	render() {
		let inneranything;
		let subinneranything;
		const nutritiondata = this.props.article.fields.nutritionsupplementFact;
		let panelData = this.props.article.fields.panel;
		let panelvalue = panelData === undefined ? null : panelData.toUpperCase();
		let ratio, val1;
		let firstIndents;
		let secondIndents;
		let thirdIndents;
		let FourthIndents;
		let fifthIndents;
		let sixthIndents;
		let seventhIndents;
		let footNutriData = nutritiondata.fields.nutitionsupplementStatement.split('\n');
		let footData;

		if (!(footNutriData === undefined)) {
			footData = footNutriData.map((x, i) => <p key={i}>{x}</p>);
		} else {
			footData = nutritiondata.fields.nutitionsupplementStatement;
		}

		return (
			<div className='maincontainer'>
				{panelvalue === 'NUTRITION' || panelvalue === 'NUTRIENT' ? (
					<div>
						<div className='topvalues'>
							<Container fluid>
								<Row>
									<Col className='topV'>
										<Col sm='3' xs='3'>
											<div className='topstyle'>
												<span className='values'>
													<h2>{nutritiondata.fields.caloriesHeadingValue}</h2>
													<h3>{nutritiondata.fields.caloriesHeadingTitle}</h3>
												</span>
											</div>
										</Col>

										<Col sm='3' xs='3'>
											<div className='topstyle'>
												<span className='values'>
													<h2>{nutritiondata.fields.satFatValue}</h2>
													<h3>{nutritiondata.fields.satFatTitle}</h3>
												</span>
											</div>
										</Col>

										<Col sm='3' xs='3'>
											<div className='topstyle'>
												<span className='values'>
													<h2>{nutritiondata.fields.sodiumValue}</h2>
													<h3>{nutritiondata.fields.sodiumTitle}</h3>
												</span>
											</div>
										</Col>

										<Col sm='3' xs='3'>
											<div className='topstyle'>
												<span className='values'>
													<h2>{nutritiondata.fields.totalSugarsValue}</h2>
													<h3>{nutritiondata.fields.totalSugarsTitle}</h3>
												</span>
											</div>
										</Col>
									</Col>
								</Row>
							</Container>
						</div>
						<section className='table-section '>
							<header className='header-part'>
								<h1 className='main-title'>{nutritiondata.fields.factTitle}</h1>
								<p className='servingcontent'>
									<span className='left'>{nutritiondata.fields.servingSizeTitle}</span>
									<span className=''>{nutritiondata.fields.servingSizeDesc}</span>
								</p>
								<p className='servingcontent'>
									<span>{nutritiondata.fields.servingsPerContainerDesc} </span>
									<span>{nutritiondata.fields.servingsPerContainerTitle}</span>
								</p>
							</header>
							<table className='main-table'>
								<thead>
									<tr>
										<th className='amountPerServingText bold'>
											{nutritiondata.fields.amountPerServingText}
										</th>
									</tr>
									<tr>
										<th className='caloris'>
											<span className='ctext bold'>{nutritiondata.fields.claoriesTitle}</span>
											<span className='cvalue'>{nutritiondata.fields.claoriesValue}</span>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className='thick-row'>
										<td className='small-info'>
											<p className='percentageDailyValueText bold right'>
												{nutritiondata.fields.percentageDailyValueText}
											</p>
										</td>
									</tr>
									{nutritiondata.fields.nutrientsupplement !== undefined
										? nutritiondata.fields.nutrientsupplement.map((x, i) => {
											if (x.fields.nutrientsupplement !== undefined) {
												inneranything = x.fields.nutrientsupplement.map((y, i) => {
													if (y.fields.nutrientsupplement !== undefined) {
														subinneranything = y.fields.nutrientsupplement.map(
															(z, i) => {
																if (z.fields.nutrientsupplementValue === '#') {
																	firstIndents = '';
																} else {
																	firstIndents = z.fields.nutrientsupplementValue;
																}
																return (
																	<p key={i}>
																		<span className='twoindent'>
																			{z.fields.nutrientsupplementName}
																		</span>
																		<span>{firstIndents}</span>
																		<span className='percentage bold right'>
																			{
																				z.fields
																					.nutrientsupplementPercentageValue
																			}
																		</span>
																	</p>
																);
															}
														);
														if (y.fields.nutrientsupplementValue === '#') {
															secondIndents = '';
														} else {
															secondIndents = y.fields.nutrientsupplementValue;
														}
														return (
															<div key={i}>
																<p className='subvalue'>
																	<span className='indent'>
																		{y.fields.nutrientsupplementName}
																	</span>
																	<span>{secondIndents}</span>
																	<span className='percentage bold  right'>
																		{y.fields.nutrientsupplementPercentageValue}
																	</span>
																</p>
																<div className='inner'>
																	<span className=''>{subinneranything}</span>
																</div>
															</div>
														);
													} else if (y.fields.nutrientsupplementName.includes('Trans')) {
														if (y.fields.nutrientsupplementValue === '#') {
															thirdIndents = '';
														} else {
															thirdIndents = y.fields.nutrientsupplementValue;
														}
														return (
															<div key={i}>
																<p>
																	<span className='indent ilatic'>
																		{y.fields.nutrientsupplementName.replace(
																			'Trans',
																			''
																		)}
																	</span>
																	<span>{thirdIndents}</span>
																	<span className='percentage bold right'>
																		{y.fields.nutrientsupplementPercentageValue}
																	</span>
																</p>
															</div>
														);
													}

													if (y.fields.nutrientsupplementValue === '#') {
														FourthIndents = '';
													} else {
														FourthIndents = y.fields.nutrientsupplementValue;
													}
													return (
														<div key={i}>
															<p>
																<span className='indent'>
																	{y.fields.nutrientsupplementName}
																</span>
																<span>{FourthIndents}</span>
																<span className='percentage bold right'>
																	{y.fields.nutrientsupplementPercentageValue}
																</span>
															</p>
														</div>
													);
												});

												if (x.fields.nutrientsupplementValue === '#') {
													fifthIndents = '';
												} else {
													fifthIndents = x.fields.nutrientsupplementValue;
												}

												return (
													<tr className='multivalues' key={i}>
														<td>
															<p className='subvalue'>
																<span className='bold'>
																	{x.fields.nutrientsupplementName}
																</span>
																<span>{fifthIndents}</span>
																<span className='percentage bold right'>
																	{x.fields.nutrientsupplementPercentageValue}
																</span>
															</p>

															{inneranything}
														</td>
													</tr>
												);
											} else {
												if (x.fields.nutrientsupplementName.includes('Vitamin A')) {
													if (x.fields.nutrientsupplementValue === '#') {
														sixthIndents = '';
													} else {
														sixthIndents = x.fields.nutrientsupplementValue;
													}

													return (
														<tr key={i}>
															<td className='last'>
																<p className='mainvaule '>
																	<span className='bold'>
																		{x.fields.nutrientsupplementName}
																	</span>
																	<span>{sixthIndents}</span>
																	<span className='percentage bold right'>
																		{x.fields.nutrientsupplementPercentageValue}
																	</span>
																</p>
															</td>
														</tr>
													);
												}

												if (x.fields.nutrientsupplementValue === '#') {
													seventhIndents = '';
												} else {
													seventhIndents = x.fields.nutrientsupplementValue;
												}

												return (
													<tr key={i}>
														<td>
															<p className='mainvaule'>
																<span className='bold'>
																	{x.fields.nutrientsupplementName}
																</span>
																<span>{seventhIndents}</span>
																<span className='percentage bold right'>
																	{x.fields.nutrientsupplementPercentageValue}
																</span>
															</p>
														</td>
													</tr>
												);
											}
										  })
										: ''}
								</tbody>
							</table>
							<p className='detailtext'>{footData}</p>

							<table className='performance-table'>
								<thead>
									<td>
										<p className='caloriestext'>
											<span className='ctext'>
												{nutritiondata.fields.tableNutritionCaloriesText}
											</span>
											<span className='cvalue1'>
												{nutritiondata.fields.tableNutritionCaloriesValue}
											</span>
											<span className='cvalue2'>
												{nutritiondata.fields.tableNutritionCaloriesValue2}
											</span>
										</p>
									</td>
								</thead>
								<tbody>
									{nutritiondata.fields.tableNutritientNameReference &&
										nutritiondata.fields.tableNutritientNameReference.map((innertable) => {
											ratio = '';
											if (innertable.fields.tableNutritientNameReference === undefined) {
												val1 = innertable.fields.tableNutritientValue;
												if (innertable.fields.tableNutritientValue.includes('<')) {
													val1 = innertable.fields.tableNutritientValue.replace('<', '');
													ratio = 'Less than';
												}
												if (innertable.fields.tableNutritientValue.includes('>')) {
													val1 = innertable.fields.tableNutritientValue.replace('>', '');
													ratio = 'Greater than';
												}
												if (innertable.fields.tableNutritientValue2.includes('<')) {
													innertable.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
														'<',
														''
													);
												}
												if (innertable.fields.tableNutritientValue2.includes('>')) {
													innertable.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
														'>',
														''
													);
												}
												return (
													<p className='valuetext'>
														<span className='tleft'>
															{innertable.fields.tableNutritientName}
														</span>
														<span className='tcenter1'>{ratio}</span>
														<span className='tcenter2'>{val1}</span>
														<span className='tright'>
															{innertable.fields.tableNutritientValue2}
														</span>
													</p>
												);
											} else {
												ratio = '';
												if (innertable.fields.tableNutritientValue.includes('<')) {
													val1 = innertable.fields.tableNutritientValue.replace('<', '');
													ratio = 'Less than';
												}
												if (innertable.fields.tableNutritientValue.includes('>')) {
													innertable.fields.tableNutritientValue = innertable.fields.tableNutritientValue.replace(
														'>',
														' Greater than'
													);
												}
												if (innertable.fields.tableNutritientValue2.includes('<')) {
													innertable.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
														'<',
														''
													);
												}
												if (innertable.fields.tableNutritientValue2.includes('>')) {
													innertable.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
														'>',
														''
													);
												}
												return (
													<div>
														<p className='valuetext'>
															<span colspan='2' className='tleft'>
																{innertable.fields.tableNutritientName}
															</span>
															<span className='tcenter1'>{ratio}</span>
															<span className='tcenter2'>{val1}</span>
															<span className='tright'>
																{innertable.fields.tableNutritientValue2}
															</span>
														</p>
														{innertable.fields.tableNutritientNameReference.map((x) => {
															ratio = '';
															if (x.fields.tableNutritientValue.includes('<')) {
																ratio = 'Less than';
																val1 = x.fields.tableNutritientValue.replace('<', '');
															}
															if (x.fields.tableNutritientValue.includes('>')) {
																x.fields.tableNutritientValue = innertable.fields.tableNutritientValue.replace(
																	'>',
																	' Greater than'
																);
															}
															if (x.fields.tableNutritientValue2.includes('<')) {
																x.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
																	'<',
																	''
																);
															}
															if (x.fields.tableNutritientValue2.includes('>')) {
																x.fields.tableNutritientValue2 = innertable.fields.tableNutritientValue2.replace(
																	'>',
																	''
																);
															}
															return (
																<p className='valuetext'>
																	<span colspan='2' className='tleft indent'>
																		{x.fields.tableNutritientName}
																	</span>
																	<span className='tcenter1'>{ratio}</span>
																	<span className='tcenter2'>{val1}</span>
																	<span className='tright'>
																		{x.fields.tableNutritientValue2}
																	</span>
																</p>
															);
														})}
													</div>
												);
											}
										})}
								</tbody>
							</table>
						</section>
					</div>
				) : (
					<Supplement article={this.props.article} />
				)}
			</div>
		);
	}
}
