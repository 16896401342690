/*eslint-disable array-callback-return*/
/*eslint-disable no-extend-native*/
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Otherinfo from '../other-information/otherinfo';
import { Container, Row, Col } from 'reactstrap';
import Company from '../Company-Brand/company';
import DrugFact from '../DrugFacts/drug';
import Allergens from '../Allergens-Nutrition/allergens';
import Nutrition from '../Nutrition/nutritionWrapper';
import Sticky from 'react-sticky-el';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SideBySideMagnifier } from 'react-image-magnifiers';
import _ from 'lodash';

export default class NutritionComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scrolling: '',
			modal: false,
			articles: props.article || [],
			productNameSelected: '',
			productIngredients: [],
			dropdownArr: [],
		};
		this.baseState = this.state;
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handlesScroll);
		if (this.props.article.fields.kit === 'YES' && this.state.productNameSelected === '') {
			const { productName } = this.props.article.fields.ingredientList[0];
			this.setState({
				productNameSelected: productName.includes('|@|') ? productName.split('|@|')[1] : productName,
				productIngredients: this.props.article.fields.ingredientList.filter(
					(ingredient) => ingredient.productName === this.props.article.fields.ingredientList[0].productName
				),
			});
		}
	}
	componentWillMount() {
		this.props.article.fields.kit &&
			this.props.article.fields.kit.toLowerCase() === 'yes' &&
			this.setState({
				dropdownArr: this.props.article.fields.ingredientList.reduce((uniqueIngredient, ingredient) => {
					if (!uniqueIngredient.includes(ingredient.productName))
						uniqueIngredient.push(ingredient.productName);
					return uniqueIngredient;
				}, []),
			});
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handlesScroll);
	}

	handlesScroll = (event) => {
		var header = document.getElementById('tab');

		if (window.innerWidth > 767) {
			if (window.scrollY > 250 && this.state.scrolling !== true) {
				this.setState({ scrolling: true });
				header.classList.add('sticky');
			} else if (window.scrollY < 250 && this.state.scrolling === true) {
				this.setState({ scrolling: false });
				header.classList.remove('sticky');
			}
		}

		if (window.innerWidth < 767) {
			if (window.scrollY > 200 && this.state.scrolling !== true) {
				this.setState({ scrolling: true });
				header.classList.add('sticky');
			} else if (window.scrollY < 200 && this.state.scrolling === true) {
				this.setState({ scrolling: false });
				header.classList.remove('sticky');
			}
		}
	};
	zoomImage = () => {
		document.getElementById('all-image-zoom').style.display = 'block';
		document.getElementsByClassName('products')[0].style.display = 'none';
		document.getElementsByClassName('zoomImage')[0].style.display = 'block';
		document.getElementsByClassName('img-logo')[0].style.visibility = 'hidden';
		document.getElementsByClassName('tabs')[0].style.display = 'none';
		document.getElementsByClassName('footer-content')[0].style.display = 'none';
	};

	getInitialState = () => {
		return {
			condition: false,
		};
	};

	handleClick = (articles, event) => {
		this.setState({
			purpose: articles.purpose,
			function: articles.function,
			ingredientName: articles.ingredientName,
		});
		if (window.innerWidth < 767) {
			document.getElementById('all-ingredient-list').style.display = 'block';
			document.getElementById('ingredient-list').style.display = 'none';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'block';
		}

		if (window.innerWidth > 767) {
			var curren = event.target.parentElement;
			var eleme = document.querySelectorAll('li.header1');
			for (var i = 0; i < eleme.length; i++) {
				if (eleme[i] === curren) {
					eleme[i].className = 'header1 active';
				} else {
					eleme[i].className = 'header1';
				}
			}
		}

		const element = this.refs.element;
		element.classList.add('blue-bg');
	};

	subingred = (articles, event) => {
		this.setState({
			ingredientNames: articles.fields.ingredientName,
			purposes: articles.fields.purpose,
			functions: articles.fields.function,
			casNumbers: articles.casNumbers,
		});

		if (window.innerWidth < 767) {
			document.getElementById('all-fragrance').style.display = 'block';
			document.getElementsByClassName('frag-ing')[0].style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'block';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'block';
			document.getElementById('all-ingredient-list').style.display = 'none';
		}

		document.getElementById('all-fragrance').style.display = 'block';
		document.getElementsByClassName('frag-ing')[0].style.display = 'none';
		document.getElementsByClassName('frag')[0].style.display = 'block';
		document.getElementsByClassName('ingredient-content')[0].style.display = 'none';
		document.getElementsByClassName('subIngrdients')[0].style.display = 'block';
		document.getElementById('all-ingredient-list').style.display = 'none';

		const elements = this.refs.elements;
		elements.classList.add('blue-bg');

		if (window.innerWidth > 767) {
			let subIngs = document.getElementsByClassName('subIngrdients')[0].offsetWidth;
			var stick = document.getElementById('sub-ing');
			stick.classList.add('sticky');
			stick.style.width = subIngs + 'px';
		}
	};

	handleChange = (event) => {
		this.setState({
			productNameSelected: JSON.parse(event.target.value).value,
			productIngredients: this.props.article.fields.ingredientList.filter((ingredient) => {
				const ing = ingredient.productName.includes('|@|')
					? ingredient.productName.split('|@|')[1]
					: ingredient.productName;
				return ing === JSON.parse(event.target.value).value;
			}),
		});
	};

	tabReset = () => {
		this.setState({
			ingredientName: null,
			purpose: null,
			function: null,
			ingredientNames: null,
			purposes: null,
			functions: null,
		});

		document.getElementById('all-fragrance').style.display = 'none';

		if (document.getElementById('all-other-info')) {
			document.getElementById('all-other-info').style.display = 'none';
		}
		var test = document.querySelectorAll('.content-list.blue-bg');

		for (let i = 0; i < test.length; i++) {
			test[i].classList.remove('blue-bg');
		}

		var getElement = document.querySelectorAll('.accor');
		var getactiveEle = document.querySelectorAll('.active');
		for (let i = 0; i < getElement.length; i++) {
			getElement[i].style.display = 'block';
		}
		for (let i = 0; i < getactiveEle.length; i++) {
			getactiveEle[i].classList.remove('active');
		}
	};

	backtoFragrance = () => {
		if (window.innerWidth < 767) {
			document.getElementsByClassName('frag-ing')[0].style.display = 'block';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
			document.getElementById('all-ingredient-list').style.display = 'block';
		} else {
			document.getElementsByClassName('frag-ing')[0].style.display = 'block';
			document.getElementsByClassName('ingredient-content')[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
			document.getElementById('all-ingredient-list').style.display = 'none';
		}
	};

	backClick = () => {
		document.getElementsByClassName('ingredient-content')[0].style.display = 'none';
		document.getElementById('all-ingredient-list').style.display = 'none';
		document.getElementById('ingredient-list').style.display = 'block';
		document.getElementsByClassName('subIngrdients')[0].style.display = 'none';
	};
	toggle = () => this.setState({ modal: !this.state.modal });

	render() {
		const navdata = this.props.article.fields;
		var i = 0;
		var subIng;
		var Ingfunc;
		var Ingfuncs;
		let panelData = this.props.article.fields.panel;
		let panelvalue = panelData === undefined ? null : panelData.toUpperCase();

		if (this.state.function === undefined || this.state.function === null) {
			Ingfunc = <div className='ingredient-desc'></div>;
		} else {
			Ingfunc = (
				<div className='ingredient-desc' ref='desc'>
					<p id='desc'>{this.state.articles.fields.brand.fields.ingredientDescription}</p>
				</div>
			);
		}

		if (this.state.functions === undefined || this.state.functions === null || this.state.functions === '') {
			Ingfuncs = <div className='ingredient-desc'></div>;
		} else {
			Ingfuncs = (
				<div className='ingredient-desc' ref='desc'>
					<p id='desc'>{this.state.articles.fields.brand.fields.ingredientDescription}</p>
				</div>
			);
		}

		if (this.state.ingredientName === 'Fragrance') {
			subIng = (
				<div>
					<h2 className='header3'>
						{this.state.articles.fields.ingredientList.map((reference) => {
							if (reference.fragranceIngredients !== undefined) {
								return reference.fragranceIngredients[0].ingredientType;
							} else {
								return null;
							}
						})}
					</h2>
					<ul className='list-item'>
						{this.state.articles.fields.ingredientList.map((reference) => {
							if (reference.fragranceIngredients !== undefined) {
								return reference.fragranceIngredients.map((opt) => (
									<li className='header1' id='sub-ingredient' onClick={() => this.subingred(opt)}>
										{/* eslint-disable-next-line */}
										<a>
											{opt.ingredientName}
											<i className='fa fa-chevron-right'></i>
										</a>
									</li>
								));
							} else {
								return null;
							}
						})}
					</ul>
				</div>
			);
		} else {
			subIng = null;
		}
		if (this.props.article.fields.kit === 'YES') {
			var kitSelect = (
				<div className='kit-dropdown'>
					<div className='select-container' data-content=''>
						<select
							onChange={this.handleChange}
							className='select'
							id='select_id'
							defaultValue={this.state.dropdownArr[0]}
						>
							{this.state.dropdownArr.length > 0 &&
								this.state.dropdownArr.map((productName, i) => (
									<option
										value={JSON.stringify({
											value: productName.includes('|@|')
												? productName.split('|@|')[1]
												: productName,
											fcgcas: productName.includes('|@|') ? productName.split('|@|')[0] : null,
										})}
										title={productName}
										key={i}
									>
										{productName.includes('|@|')
											? productName.split('|@|')[1]
											: productName.split('~')[0]}
									</option>
								))}
						</select>
					</div>
				</div>
			);

			let list = {};

			this.state.productIngredients.map((y) => {
				if (!list[y.ingredientType]) list[y.ingredientType] = [];
				list[y.ingredientType].push({
					ingredientName: y.ingredientName,
					purpose: y.purpose,
					function: y.function,
					sequenceOrder: y.sequenceOrder,
				});
			});
			Array.prototype.sortBy = function (p) {
				return this.slice(0).sort(function (a, b) {
					return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
				});
			};

			if (this.state.productIngredients !== undefined && this.state.productIngredients.length > 0)
				if (
					this.state.productIngredients !== undefined &&
					list[this.state.productIngredients[0].ingredientType] !== undefined
				) {
					var seqOd = list[this.state.productIngredients[0].ingredientType].map((x) => {
						var y = {};
						y['ingredientName'] = x['ingredientName'];
						y['purpose'] = x['purpose'];
						y['function'] = x['function'];
						y['sequenceOrder'] = parseInt(x['sequenceOrder']);
						return y;
					});
					list[this.state.productIngredients[0].ingredientType] = seqOd.sortBy('sequenceOrder');
				}

			var kit = (
				<div className='kit'>
					<div id='ingredient-list' className={this.state.condition ? ' hide' : 'show'}>
						{Object.keys(list)
							.sort()
							.map((key, i) => (
								<section key={i}>
									<h4 className='header3'>{key.split('@@*')[0]}</h4>
									<ul onClick={this.onClick} className='list-item' ref='ingList' id='list-item'>
										{list[key].map((ingredient, i) => {
											var seqOrd = ingredient.sequenceOrder;
											if (ingredient.purpose || ingredient.function)
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														onClick={(event) => this.handleClick(ingredient, event)}
														key={i}
														data-order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a className=''>
															<span className='fren-ing'>
																{this.props.local &&
																this.props.local.toLowerCase() === 'fr-ca' &&
																navdata.brand.fields.frenchIngredientList
																	? navdata.brand.fields.frenchIngredientList.map(
																		(ing) => {
																			if (
																				ingredient.ingredientName
																					.toLowerCase()
																					.trim() ===
																					ing.EnglishIngredient.toLowerCase().trim()
																			) {
																				return ing.FrenchIngredient;
																			}
																		}
																	  )
																	: ingredient.ingredientName}
															</span>
															<span className='main-ing'>
																{ingredient.ingredientName}
															</span>
															<i className='fa fa-chevron-right'></i>
														</a>
													</li>
												);
											else
												return (
													<li
														ref='list'
														id='ingredient'
														className='header1 notclick'
														onClick={() => this.handleClick(ingredient)}
														key={i}
														data-order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a className=''>{ingredient.ingredientName}</a>
													</li>
												);
										})}
									</ul>
								</section>
							))}
					</div>
				</div>
			);
		} else {
			let list = {};

			this.props.article.fields.ingredientList.map((y) => {
				if (y !== undefined) {
					if (!list[y.ingredientType]) list[y.ingredientType] = [];
					list[y.ingredientType].push({
						ingredientName: y.ingredientName,
						purpose: y.purpose,
						function: y.function,
						sequenceOrder: y.sequenceOrder,
					});
				}
			});
			Array.prototype.sortBy = function (p) {
				return this.slice(0).sort(function (a, b) {
					return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
				});
			};

			if (this.state.productIngredients !== undefined && this.state.productIngredients.length > 0)
				if (
					this.state.productIngredients !== undefined &&
					list[this.state.productIngredients[0].ingredientType] !== undefined
				) {
					var seqOd2 = list[this.state.productIngredients[0].ingredientType].map((x) => {
						var y = {};
						y['ingredientName'] = x['ingredientName'];
						y['purpose'] = x['purpose'];
						y['function'] = x['function'];
						y['sequenceOrder'] = parseInt(x['sequenceOrder']);
						return y;
					});
					list[this.state.productIngredients[0].ingredientType] = seqOd2.sortBy('sequenceOrder');
				}
			let ingrFoot = navdata.ingredientFooterData;

			let footData = ingrFoot !== undefined ? ingrFoot.split(/:/) : null;

			let footDataTitle = footData !== undefined && footData !== null ? footData[0] : null;
			let footDataContent = footData !== undefined && footData !== null ? footData[1] : null;

			let footercontent;

			if (footData !== undefined || footData !== null) {
				footercontent = (
					<section className='footerData'>
						<h4>{footDataTitle}</h4>
						<p className='footContent'>{footDataContent}</p>
					</section>
				);
			} else {
				footercontent = null;
			}

			var ingred = (
				<div id='ingredient-list' className={this.state.condition ? ' hide' : 'show'}>
					{Object.keys(list)
						.sort()
						.map((key, i) => (
							<section key={i}>
								<h4 className='header3'>{key.split('@@*')[0]}</h4>
								<ul onClick={this.onClick} className='list-item' ref='ingList'>
									{list[key]
										.sort((a, b) => a.sequenceOrder - b.sequenceOrder)
										.map((ingredient, i) => {
											var ingreName = ingredient.ingredientName;
											var seqOrd = ingredient.sequenceOrder;

											if (ingredient.purpose || ingredient.function)
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														onClick={(event) => this.handleClick(ingredient, event)}
														key={i}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a className=''>
															{ingredient.ingredientName}
															<i className='fa fa-chevron-right'></i>
														</a>
													</li>
												);
											else if (
												ingreName.endsWith(':') ||
												ingredient.purpose ||
												ingredient.function
											) {
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														key={i}
														style={{ cursor: 'default' }}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a style={{ cursor: 'default' }} className='green'>
															{ingredient.ingredientName}
														</a>
													</li>
												);
											} else {
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														key={i}
														style={{ cursor: 'default' }}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a style={{ cursor: 'default' }} className=''>
															{ingredient.ingredientName}
														</a>
													</li>
												);
											}
										})}
								</ul>
							</section>
						))}
					<div>{footercontent}</div>
				</div>
			);
		}
		const oldVersion = () => {
			return (
				//  eslint-disable-next-line
				<a className='prd-image' onClick={() => this.zoomImage()}>
					<img
						id='product-image'
						src={navdata.productImage.fields.file.url}
						style={{ width: '120px', height: '120px' }}
						alt=''
					/>
				</a>
			);
		};
		const newChanges = (
			<section>
				<div className='prd-image'>
					<img
						onClick={this.toggle}
						id='product-image'
						src={navdata.productImage.fields.file.url}
						style={{ height: '120px', cursor: 'zoom-in' }}
						alt=''
					/>
					<span className='click-to-zoom' onClick={this.toggle}>
						<img
							src='https://image.flaticon.com/icons/png/512/117/117453.png'
							className='zoom-in-icon'
							alt=''
						/>
						&nbsp;{' '}
						{this.props.local && this.props.local === 'fr-CA' ? 'Cliquer pour agrandir' : 'Click to zoom'}
					</span>
				</div>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className='modal-center-align'>
					<ModalHeader toggle={this.toggle} charCode='&#10005;'>
						Product Image
					</ModalHeader>

					<ModalBody className='modalbody'>
						{/* <ReactImageZoom {...imageProps} /> */}

						<SideBySideMagnifier
							imageSrc={navdata.productImage.fields.file.url}
							imageAlt='image'
							cursorStyle='crosshair'
							alwaysInPlace={true}
							transitionSpeed={0.3}
							style={{ height: 'auto', width: 'auto' }}
						/>
						<div style={{ textAlign: 'center' }}>Slide over to zoom</div>
					</ModalBody>
				</Modal>
			</section>
		);
		let locale = this.props.local;
		const nettContent = navdata.nettProduct.includes('/')
			? locale.toLowerCase() === 'en-us'
				? navdata.nettProduct.split('/')[0]
				: navdata.nettProduct.split('/')[1]
			: navdata.nettProduct;
		return (
			<div>
				<div className='products'>
					<Container fluid>
						<Row>
							<Col sm='6' xs='8'>
								<div className='sub-header' id='brand-name'>
									{navdata.brandName}
								</div>
								<div className='header1' id='product-name'>
									{navdata.productName}
								</div>
								<div className='sub-header header2' id='nett-content'>
									{nettContent}
								</div>
							</Col>
							<Col sm='6' xs='4'>
								<div className='product-img'>
									<div className='product-section'>
										{newChanges}

										<div className='sub-header header2'>{navdata.productImage.fields.title}</div>
									</div>
								</div>
							</Col>
							<Col sm='12'>{kitSelect}</Col>
						</Row>
					</Container>
				</div>
				<div className='zoomImage'>
					<div className='section-container'>
						<Container>
							<Row>
								<Col sm='12'>
									<img src={navdata.productImage.fields.file.url} alt='' />
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				<Tabs className='tabs'>
					<TabList
						id='tab'
						style={{
							color: '#06DCD6',
							borderWidth: 0,
							position: this.state.scrolling ? 'fixed' : 'relative',
							top: 0,
							width: '100vw',
							zIndex: 1,
						}}
					>
						<Tab onClick={this.tabReset}>
							<div id='food-tab'>
								{panelvalue === 'NUTRITION' || panelvalue === 'NUTRIENT' ? (
									<span id='food-header'>
										<i className='fa fa-cutlery'></i>
										{navdata.nonFoodInstruction.fields.nutritionsupplementHeading}
									</span>
								) : (
									<span id='food-header'>
										<i className='fa fa-search'></i>
										{navdata.nonFoodInstruction.fields.nutritionsupplementHeading}
									</span>
								)}
							</div>
						</Tab>
						<Tab onClick={this.tabReset}>
							<div id='ingredient-tab'>
								<i className='fa fa-list'></i>
								{navdata.nonFoodInstruction.fields.ingredientsHeading}
							</div>
						</Tab>
						<Tab onClick={this.tabReset}>
							<div>
								<i className='fa fa-exclamation-triangle'></i>
								<span>{navdata.nonFoodInstruction.fields.allergenHeading}</span>
							</div>
						</Tab>
						<Tab onClick={this.tabReset}>
							<div>
								<i className='fa fa-info-circle'></i>
								{this.props.local === 'en-US'
									? 'Features & Benefits'
									: navdata.nonFoodInstruction.fields.otherInformationHeading}
							</div>
						</Tab>
						<Tab onClick={this.tabReset}>
							<div>
								<i className='fa fa-share-square'></i>
								{this.props.local === 'en-US'
									? 'Company, Brand & Sustainability'
									: navdata.companyCommonData.fields.companyHeading}
							</div>
						</Tab>
					</TabList>

					<TabPanel>
						<Nutrition article={this.props.article} />
					</TabPanel>

					<TabPanel>
						<Container fluid>
							<Row>
								<Col>
									<DrugFact article={this.props.article} key={i} />
									<div id='ingredients'>
										<Container fluid className='top-20'>
											<div className='ingredients'>
												<Row>
													<Col sm='4'>
														{kit}
														{ingred}
													</Col>
													<Col sm='8'>
														<div className='ingredient-content'>
															<Sticky>
																<div ref='element' className='content-list'>
																	<div
																		className='back'
																		id='all-ingredient-list'
																		onClick={() => this.backClick()}
																	>
																		<i
																			className='fa fa-chevron-left fa-5'
																			aria-hidden='true'
																		></i>
																	</div>
																	<p className='header1 title ' id='ing-title'>
																		{this.state.ingredientName}
																	</p>
																	<p className='paragraph purpose ' id='ing-purpose'>
																		{this.state.purpose}
																	</p>
																	<p
																		className='paragraph function '
																		id='ing-function'
																	>
																		{this.state.function}
																	</p>
																</div>

																{Ingfunc}
															</Sticky>
															{subIng}
														</div>

														<div className='subIngrdients'>
															<div id='sub-ing'>
																<Sticky>
																	<div ref='elements' className='content-list frag'>
																		<div
																			className='back'
																			id='all-fragrance'
																			onClick={this.backtoFragrance}
																		>
																			<i
																				className='fa fa-chevron-left fa-5'
																				aria-hidden='true'
																			></i>
																		</div>
																		<p className='header1 title'>
																			{this.state.ingredientNames}
																		</p>
																		<p className='paragraph purpose'>
																			{this.state.purposes}
																		</p>
																		<p className='paragraph function'>
																			{this.state.functions}
																		</p>
																	</div>
																	{Ingfuncs}
																</Sticky>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</Container>
									</div>
								</Col>
							</Row>
						</Container>
					</TabPanel>

					<TabPanel>
						<div id='allergens'>
							<Allergens article={this.props.article} local={this.props.local} />
						</div>
					</TabPanel>

					<TabPanel>
						<div id='otherm'>
							<Otherinfo article={this.props.article} local={this.props.local} />
						</div>
					</TabPanel>

					<TabPanel>
						<div id='companym'>
							<Company article={this.props.article} />
						</div>
					</TabPanel>
				</Tabs>
			</div>
		);
	}
}
