import React from 'react';

export default class Allergens extends React.Component {
	render() {
		const allergData = this.props.article.fields.allergen;
		const allerge = this.props.article.fields;
		let allerg;
		let allergenSatemet;
		let allergNoText;

		if (
			allergData === undefined ||
			(allergData.fields &&
				allergData.fields.allergenContainment === 'N/A' &&
				allergData.fields.allergenStatement === 'N/A')
		) {
			allergNoText = <p className='paragraph' id='allergen'>{allerge.companyCommonData.fields.noAllergenStatement}</p>;
		}

		if (allergData !== undefined && allergData.fields.allergenStatement !== 'N/A') {
			allergenSatemet = <p className='paragraph' id='allergen'>{allergData.fields.allergenStatement}</p>;
		}

		if (allergData !== undefined && allergData.fields.allergenContainment !== 'N/A') {
			allerg = <p className='paragraph' id='allergen'>{allergData.fields.allergenContainment}</p>;
		}

		return (
			<div className='allergens'>
				<p className='blue-bg'>{allerge.companyCommonData.fields.allergenFdaStatement}</p>
				{allerg}
				{allergenSatemet}
				{allergNoText}
			</div>
		);
	}
}
