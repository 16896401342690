import React from 'react';
import { 
	FacebookShareButton, 
	PinterestShareButton, 
	TwitterShareButton 
} from 'react-share';
import {
	FacebookIcon, 
	PinterestIcon, 
	TwitterIcon
} from 'react-share';


export default class Socialshare extends React.Component{
	render() {
		const hostName = window.location.host;
		const shareUrl = 'http://'+hostName+window.location.pathname;
		return(
			<div className="social-icons">
				<FacebookShareButton url={shareUrl} >
					<FacebookIcon size={32}/>
				</FacebookShareButton>
				<PinterestShareButton media={shareUrl} url={shareUrl}>
					<PinterestIcon size={32} />
				</PinterestShareButton>
				<TwitterShareButton url={shareUrl}>
					<TwitterIcon size={32}/>
				</TwitterShareButton>
			</div>
		);
	}
}