/* eslint-disable no-unused-vars */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable eqeqeq */
/* eslint-disable no-extend-native*/
/* eslint-disable array-callback-return*/

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Usage from '../Uage-Instructions/usage';
import Advisory from '../Advisory-handling/advisory';
import Otherinfo from '../other-information/otherinfo';
import { Container, Row, Col } from 'reactstrap';
import Company from '../Company-Brand/company';
import DrugFact from '../DrugFacts/drug';
import Sticky from 'react-sticky-el';
import _ from 'lodash';
import './artwork.scss';
import Headings from './headings';
import Casnumbers from '../../JSONData/casnumbers';
import FragranceFunction from '../../JSONData/fragrance-function.json';
import FragranceFunctionFrench from '../../JSONData/fragrance-function-French.json';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { SideBySideMagnifier } from 'react-image-magnifiers';
// import ReactImageZoom from 'react-image-zoom';
import FragranceConser from '../../JSONData/fragrance-conservatory.json';
import Cocdata from '../../JSONData/cocdata';

export default class BodyComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gtin: props.article.fields.gtin,
			scrolling: '',
			articles: props.article || [],
			isSmartphone: window.innerWidth < 767,
			fragranceArr: [
				'Fragrance',
				'Fragrances',
				'Perfume',
				'Perfumes',
				'Parfum',
				'Fragrance/Parfum',
				'FRAGRANCE',
				'FRAGRANCES',
				'Scent',
				'OdorLock™ technology',
				'Odor Neutralizing Material'
			],
			productNameSelected: null,
			productIngredients: [],
			dropdownArr: [],
			casnumber: '',
			local: props.local,
			cocarr: [],
			cocBrands: [
				'9 Elements',
				'All in Oneder',
				'Ariel',
				'Bounce',
				'Bounce Professional',
				'Braun',
				'Cascade',
				'Cheer',
				'Dawn',
				'Dawn Professional',
				'Downy',
				'Downy Professional',
				'Dreft',
				'Era',
				'Febreze',
				'Febreze Professional',
				'Gain',
				'Home Made Simple',
				'Ivory Snow',
				'Joy',
				'Microban',
				'Mr. Clean',
				'Mr Clean',
				'NBD.',
				'Studio by Tide',
				'Studio for Tide',
				'Swiffer',
				'Tide',
				'Tide Professional',
				'PGProLine Professional',
				'Ivory'
			],
			isCABrand: false,
			// ingredientLists: [
			// 	'Polyester / Polyethylene /Polypropylene',
			// 	'Melamine Resin Foam',
			// 	'Mineral Oil Finish',
			// 	'Polyester / Polypropylene',
			// 	'Wax-Based Finish',
			// 	'Polypropylene / Polyester / Cellulose',
			// ],
			propsIngredientList: []
		};
	}

	showChemicalOfConcern = () => {
		let cocarr = [];
		this.state.propsIngredientList.map((val) => {
			if (val && val.chemicalOfConcern) {
				let newarr = val.chemicalOfConcern.split(',');
				newarr.forEach((na) => na.trim() && cocarr.push(na.trim()));
			}

			if (val && val.fragranceIngredients !== undefined) {
				val.fragranceIngredients.forEach((val) => {
					Object.keys(val).forEach((item) => {
						if (val.chemicalOfConcern) {
							let newarr = val.chemicalOfConcern.split(',');
							newarr.forEach(
								(na) => na.trim() && cocarr.push(na.trim())
							);
						}
					});
				});
			}

			if (val && val.subIngredients !== undefined) {
				val.subIngredients.forEach((val) => {
					Object.keys(val).forEach((item) => {
						if (val.chemicalOfConcern) {
							let newarr = val.chemicalOfConcern.split(',');
							newarr.forEach(
								(na) => na.trim() && cocarr.push(na.trim())
							);
						}
					});
				});
			}
		});
		this.setState({
			isloading: false,
			cocarr: [...new Set(cocarr)]
		});
		var selectContainer = document.querySelector('.select-container');
		if (
			document.getElementsByClassName('select')[0] !== undefined &&
			document.getElementsByClassName('select')[0] !== null
		) {
			var select = selectContainer.querySelector('.select');
			selectContainer.dataset.content = select.value;
			function handleChange(e) {
				selectContainer.dataset.content = e.currentTarget.value;
			}

			select.addEventListener('change', handleChange);
		}
	};

	componentDidMount() {
		const isCABrand = this.state.articles.fields.brandName.trim() === 'Ivory' ?
			this.state.articles.fields.productName.toLowerCase().includes('detergent') ||
			this.state.articles.fields.productName.toLowerCase().includes('dish soap') :
			this.state.cocBrands.find(
				(cab) =>
					cab.toLowerCase().replace(/ /g, '').replace(/\./g, '') ===
					this.state.articles.fields.brandName
						.toLowerCase()
						.replace(/ /g, '')
						.replace(/\./g, '')
			);
		this.setState({ isCABrand });
		window.addEventListener('scroll', this.handlesScroll);
		if (
			this.props.article.fields.kit &&
			this.props.article.fields.kit.toLowerCase() === 'yes'
		) {
			const listOfIngredients = Object.assign(
				[],
				this.props.article.fields.ingredientList
			);
			listOfIngredients.forEach((ing) => {
				ing.productName = ing.productName.includes('|@|')
					? ing.productName.split('|@|')[1]
					: ing.productName.includes('~CA@@*') ||
						ing.productName.includes('~CA @@*')
						? ing.productName.split('~CA')[0]
						: ing.productName.includes('@@*')
							? ing.productName.split('@@*')[0]
							: ing.productName.split('~')[0];
			});
			this.setState(
				{
					propsIngredientList: listOfIngredients
				},
				() => {
					const { productName } = this.state.propsIngredientList[0];

					const ingList = this.state.propsIngredientList.reduce(
						(uniqueIngredient, ingredient) => {
							if (
								!uniqueIngredient.includes(
									ingredient.productName
								)
							)
								uniqueIngredient.push(ingredient.productName);
							return uniqueIngredient;
						},
						[]
					);
					this.setState({ dropdownArr: ingList });

					if (
						this.state.productNameSelected === null &&
						this.props.article.fields.kit &&
						this.props.article.fields.kit.toLowerCase() === 'yes'
					) {
						this.setState({
							productNameSelected: productName,
							productIngredients:
								this.state.propsIngredientList.filter(
									(ingredient) =>
										ingredient.productName === productName
								)
						});
					}
					this.showChemicalOfConcern();
				}
			);
		} else {
			const listOfIngredients = Object.assign(
				[],
				this.props.article.fields.ingredientList
			);
			this.setState({ propsIngredientList: listOfIngredients }, () => {
				this.showChemicalOfConcern();
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handlesScroll);
	}

	handlesScroll = () => {
		var header = document.getElementById('tab');
		// let sticky = header.offsetTop;

		if (window.innerWidth > 767) {
			if (window.scrollY > 250 && this.state.scrolling !== true) {
				this.setState({ scrolling: true });
				header.classList.add('sticky');
			} else if (window.scrollY < 250 && this.state.scrolling === true) {
				this.setState({ scrolling: false });
				header.classList.remove('sticky');
			}
		}

		if (window.innerWidth < 767) {
			if (window.scrollY > 400 && this.state.scrolling !== true) {
				this.setState({ scrolling: true });
				header.classList.add('sticky');
			} else if (window.scrollY < 400 && this.state.scrolling === true) {
				this.setState({ scrolling: false });
				header.classList.remove('sticky');
			}
		}
	};

	zoomImage = () => {
		document.getElementById('all-image-zoom').style.display = 'block';
		document.getElementsByClassName('products')[0].style.display = 'none';
		document.getElementsByClassName('zoomImage')[0].style.display = 'block';
		document.getElementsByClassName('img-logo')[0].style.visibility =
			'hidden';
		document.getElementsByClassName('tabs')[0].style.display = 'none';
		document.getElementsByClassName('footer-content')[0].style.display =
			'none';
		document.getElementsByClassName('country-panel')[0].style.display =
			'none';
	};

	getInitialState = () => {
		return {
			condition: false
		};
	};

	handleClick = (articles, event) => {
		this.state.fragranceArr.includes(articles.ingredientName) &&
			window.scrollTo(
				document.getElementsByClassName('react-tabs__tab-list')[0]
					.clientHeight,
				0
			);
		if (this.state.local.toLowerCase() === 'en-us') {
			let casnumber = '';

			if (this.state.isCABrand) {
				if (articles.casNumber && articles.casNumber !== '') {
					casnumber = articles.casNumber;
				} else {
					Object.keys(Casnumbers).forEach((val) => {
						if (
							Casnumbers[val]['IngredientName']
								.toLowerCase()
								.trim() ===
							articles.ingredientName.toLowerCase()
						) {
							casnumber = Casnumbers[val]['CASNo.'];
						}
					});
				}
			}

			if (casnumber === '') {
				casnumber = 'Not Available';
			}

			if (
				this.state.articles.fields.brandName == '9 Elements' &&
				articles.ingredientName.toLowerCase() == 'c10-16 pareth'
			) {
				casnumber = '68551-12-2';
			}

			if (
				this.state.articles.fields.brandName == 'Cascade' &&
				articles.ingredientName.toLowerCase() == 'calcium chloride'
			) {
				casnumber = '10035-04-8';
			}

			if (
				this.state.articles.fields.brandName == '9 Elements' &&
				articles.ingredientName.toLowerCase() == 'sodium citrate'
			) {
				casnumber = '994-36-5';
			}

			this.setState({ casnumber });
		}
		this.setState({
			purpose: articles.purpose,
			function: articles.function,
			ingredientName: articles.ingredientName,
			designatedList: articles.chemicalOfConcern
		});

		let ingredientN;

		if (
			this.props.local === 'fr-CA' &&
			this.props.article.fields.brand.fields.frenchIngredientList !=
			undefined
		) {
			this.props.article.fields.brand.fields.frenchIngredientList.forEach(
				(state) => {
					if (
						state.EnglishIngredient.toLowerCase().trim() ===
						articles.ingredientName.toLowerCase()
					) {
						return (ingredientN = state.FrenchIngredient);
					}
				}
			);
			if (ingredientN == undefined) {
				ingredientN = articles.ingredientName;
			}
			this.setState({ ingredientName: ingredientN });
		}

		if (window.innerWidth < 767) {
			document.getElementById('all-ingredient-list').style.display =
				'block';
			document.getElementById('ingredient-list').style.display = 'none';
			document.getElementsByClassName(
				'ingredient-content'
			)[0].style.display = 'block';
		}

		if (window.innerWidth > 767) {
			var curr = event.target.parentElement;
			var elem = document.querySelectorAll('li.header1');
			for (var i = 0; i < elem.length; i++) {
				if (elem[i] === curr) {
					elem[i].className = 'header1 active';
				} else {
					elem[i].className = 'header1';
				}
			}
		}

		const element = this.refs.element;
		element.classList.add('blue-bg');
	};

	subingred = (articles) => {
		window.scrollTo(
			document.getElementsByClassName('react-tabs__tab-list')[0]
				.clientHeight,
			0
		);
		if (this.state.local.toLowerCase() === 'en-us') {
			let casnumber = '';

			if (this.state.isCABrand) {
				if (articles.casNumber && articles.casNumber !== '') {
					casnumber = articles.casNumber;
				} else {
					Object.keys(Casnumbers).forEach((val) => {
						if (
							Casnumbers[val]['IngredientName']
								.toLowerCase()
								.trim() ===
							articles.ingredientName.toLowerCase()
						) {
							casnumber = Casnumbers[val]['CASNo.'];
						}
					});
				}
			}
			if (casnumber === '') {
				casnumber = 'Not Available';
			}
			this.setState({ casNumbers: casnumber });
		}

		let fragrance = '';

		if (
			this.props.local.toLowerCase() === 'en-us' ||
			this.props.local.toLowerCase() == 'en-ca'
		) {
			Object.keys(FragranceFunction).forEach((statement) => {
				if (
					FragranceFunction[statement]['IngredientName']
						.toLowerCase()
						.trim() === articles.ingredientName.toLowerCase().trim()
				) {
					fragrance = FragranceFunction[statement]['Function'];
				}
			});
			this.setState({ FragranceState: fragrance });
		} else {
			Object.keys(FragranceFunctionFrench).forEach((statement) => {
				if (
					FragranceFunctionFrench[statement]['IngredientName']
						.toLowerCase()
						.trim() === articles.ingredientName.toLowerCase().trim()
				) {
					fragrance = FragranceFunctionFrench[statement]['Function'];
				}
			});
			this.setState({ FragranceState: fragrance });
		}

		let fragranceURL = '',
			fragranceCons = '';

		if (
			this.props.local.toLowerCase() === 'en-us' ||
			this.props.local.toLowerCase() == 'en-ca'
		) {
			Object.keys(FragranceConser).forEach((statements) => {
				if (
					FragranceConser[statements]['Ingredient']
						.toLowerCase()
						.trim() === articles.ingredientName.toLowerCase()
				) {
					fragranceCons =
						'For additional information about this ingredient, please see';
					fragranceURL = FragranceConser[statements]['URL'].trim();
				}
			});
			this.setState({
				FragranceCons: fragranceCons,
				FragranceURL: fragranceURL
			});
		} else {
			Object.keys(FragranceConser).forEach((statements) => {
				if (
					FragranceConser[statements]['Ingredient']
						.toLowerCase()
						.trim() === articles.ingredientName.toLowerCase()
				) {
					fragranceCons =
						'Pour des informations supplémentaires quant à cet ingrédient, veuillez consulter le';
					fragranceURL = FragranceConser[statements]['URL'].trim();
				}
			});
			this.setState({
				FragranceCons: fragranceCons,
				FragranceURL: fragranceURL
			});
		}

		this.setState({
			ingredientNames: articles.ingredientName.toLowerCase(),
			purposes: articles.purpose,
			functions: articles.function,
			designatedList:
				articles.chemicalOfConcern && articles.chemicalOfConcern.trim()
					? articles.chemicalOfConcern.trim()
					: null
		});

		let ingredientN;
		if (
			this.props.local === 'fr-CA' &&
			this.props.article.fields.brand.fields
				.frenchFragranceIngredientList != undefined
		) {
			this.props.article.fields.brand.fields.frenchFragranceIngredientList.forEach(
				(state) => {
					if (
						state.EnglishIngredient.toLowerCase().trim() ===
						articles.ingredientName.toLowerCase()
					) {
						return (ingredientN = state.FrenchIngredient);
					}
				}
			);
			if (ingredientN == undefined) {
				ingredientN = articles.ingredientName;
			}
			this.setState({ ingredientNames: ingredientN });
		}

		if (window.innerWidth < 767) {
			document.getElementById('all-fragrance').style.display = 'block';
			document.getElementsByClassName('frag-ing')[0].style.display =
				'none';
			document.getElementsByClassName('frag')[0].style.display = 'block';
			document.getElementsByClassName(
				'ingredient-content'
			)[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display =
				'block';
			document.getElementById('all-ingredient-list').style.display =
				'none';
		}

		document.getElementById('all-fragrance').style.display = 'block';
		document.getElementsByClassName('frag-ing')[0].style.display = 'none';
		document.getElementsByClassName('frag')[0].style.display = 'block';
		document.getElementsByClassName('ingredient-content')[0].style.display =
			'none';
		document.getElementsByClassName('subIngrdients')[0].style.display =
			'block';
		document.getElementById('all-ingredient-list').style.display = 'none';

		const elements = this.refs.elements;
		elements.classList.add('blue-bg');

		if (window.innerWidth > 767) {
			let subIngs =
				document.getElementsByClassName('subIngrdients')[0].offsetWidth;
			var stick = document.getElementById('sub-ing');
			// stick.classList.add('sticky');
			stick.style.width = subIngs + 'px';
		}
	};
	handleChange = (event) => {
		this.setState({
			productNameSelected: JSON.parse(event.target.value).value,
			productIngredients: this.state.propsIngredientList.filter(
				(ingredient) => {
					const ing = ingredient.productName.includes('|@|')
						? ingredient.productName.split('|@|')[1]
						: ingredient.productName;
					return ing === JSON.parse(event.target.value).value;
				}
			)
		});
	};

	tabReset = () => {
		this.setState({
			ingredientName: null,
			purpose: null,
			function: null,
			ingredientNames: null,
			purposes: null,
			functions: null,
			casNumbers: null,
			casnumber: null,
			designatedList: null
		});

		document.getElementById('all-fragrance').style.display = 'none';
		if (document.getElementById('all-other-info')) {
			document.getElementById('all-other-info').style.display = 'none';
		}
		var test = document.querySelectorAll('.content-list.blue-bg');

		for (let i = 0; i < test.length; i++) {
			test[i].classList.remove('blue-bg');
		}

		var getElement = document.querySelectorAll('.accor');
		var getactiveEle = document.querySelectorAll('.active');
		for (let i = 0; i < getElement.length; i++) {
			getElement[i].style.display = 'block';
		}
		for (let i = 0; i < getactiveEle.length; i++) {
			getactiveEle[i].classList.remove('active');
		}
	};

	backtoFragrance = () => {
		if (window.innerWidth < 767) {
			document.getElementsByClassName('frag-ing')[0].style.display =
				'block';
			document.getElementsByClassName(
				'ingredient-content'
			)[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display =
				'none';
			document.getElementById('all-ingredient-list').style.display =
				'block';
		} else {
			document.getElementsByClassName('frag-ing')[0].style.display =
				'block';
			document.getElementsByClassName(
				'ingredient-content'
			)[0].style.display = 'block';
			document.getElementById('all-fragrance').style.display = 'none';
			document.getElementsByClassName('frag')[0].style.display = 'none';
			document.getElementsByClassName('subIngrdients')[0].style.display =
				'none';
			document.getElementById('all-ingredient-list').style.display =
				'none';
		}
	};

	backClick = () => {
		document.getElementsByClassName('ingredient-content')[0].style.display =
			'none';
		document.getElementById('all-ingredient-list').style.display = 'none';
		document.getElementById('ingredient-list').style.display = 'block';
		document.getElementsByClassName('subIngrdients')[0].style.display =
			'none';
	};
	toggle = (modal) => this.setState({ [modal]: !this.state[modal] });
	render() {
		const findDL = (dl) => {
			return Cocdata[
				Object.keys(Cocdata).filter((coc) =>
					coc
						.toLowerCase()
						.replace(/ /g, '')
						.includes(dl.toLowerCase().replace(/ /g, ''))
				)
			];
		};
		const DesignatedLists =
		this.state.gtin !== '11137000739739'
			? this.state.isCABrand &&
				this.state.designatedList && (
				<div className='designated-lists'>
					<div className='dl-title'>Designated List</div>
					{this.state.designatedList
						.split(',')
						.map((dl, id) => (
							<span
								key={`dl-${id}`}
								className='dl-name'
								onClick={() => this.toggle('dlModal')}
							>
								{findDL(dl).name.replace('.', '')}
								<Modal
									isOpen={this.state.dlModal}
									toggle={() =>
										this.toggle('dlModal')
									}
									className='center-align'
								>
									<ModalHeader
										toggle={() =>
											this.toggle('dlModal')
										}
									>
										{
											this.props.article.fields
												.companyCommonData
												.fields
												.leavingSmartLabelTitle
										}
									</ModalHeader>
									<ModalBody>
										{
											this.props.article.fields
												.companyCommonData
												.fields
												.leavingSmartLabelDescription
										}
									</ModalBody>
									<ModalFooter>
										<Button
											color='secondary'
											onClick={() =>
												this.toggle('dlModal')
											}
										>
												Cancel
										</Button>
										<Button
											color='primary'
											href={findDL(dl).url}
											target='_blank'
											onClick={() =>
												this.toggle('dlModal')
											}
										>
												Continue
										</Button>
									</ModalFooter>
								</Modal>
							</span>
						))}
					<div className='dl-desc'>
							Under the California Cleaning Product Right to
							Know Act, the disclosure of fragrance allergens
							included on Annex III of the EU Cosmetics
							Regulation No 1223/2009, ingredients that appear
							on one or more of 22 Designated Lists, and/or
							certain Nonfunctional Constituents are required
							to provide consumers and workers with detailed
							ingredient information about the cleaning
							products they purchase.
					</div>
				</div>
			)
			: null;
		const oldVersion = () => {
			return (
				//   eslint-disable-next-line
				<a className='prd-image' onClick={() => this.zoomImage()}>
					<img
						id='product-image'
						src={navdata.productImage.fields.file.url}
						style={{ width: '120px', height: '120px' }}
						alt=''
					/>
				</a>
			);
		};
		// FOR DL active on EN-CA remove local condition from below if statement
		const DLIcon = (ing) => {
			if (

				this.state.isCABrand &&
				ing &&
				ing.chemicalOfConcern &&
				this.state.local.toLowerCase() === 'en-us' &&
				ing.chemicalOfConcern.trim()
			)
				return <span className='dl-icon '>DL</span>;
		};

		const navdata = this.props.article.fields;
		var i = 0;
		let headings =
			this.props.article.fields.panel === 'NUTRITION' ||
				this.props.article.fields.panel === 'NUTRIENT'
				? Headings['food']
				: Headings['nonfood'];
		let subIng;
		let Ingfunc;
		let Ingfuncs;
		let GTINtitle;
		let medicalDevice;
		let drugIdentification;
		let naturalhealth;
		let locale = this.props.local;
		// let gtin = this.props.gtin;
		let updatedDinValue;
		const newChanges = (
			<section>
				<div className='prd-image'>
					<img
						onClick={() => this.toggle('modal')}
						id='product-image'
						src={navdata.productImage.fields.file.url}
						style={{ height: '120px', cursor: 'zoom-in' }}
						alt=''
					/>
					<span
						className='click-to-zoom'
						onClick={() => this.toggle('modal')}
					>
						<img
							src='https://image.flaticon.com/icons/png/512/117/117453.png'
							className='zoom-in-icon'
							alt=''
						/>
						&nbsp;{' '}
						{this.props.local.toLowerCase() === 'fr-ca'
							? 'Cliquer pour agrandir'
							: 'Click to zoom'}
					</span>
				</div>
				<Modal
					isOpen={this.state.modal}
					toggle={() => this.toggle('modal')}
					className='modal-center-align'
				>
					<ModalHeader
						toggle={() => this.toggle('modal')}
						charCode='&#10005;'
					>
						Product Image
					</ModalHeader>

					<ModalBody className='modalbody'>
						{/* <ReactImageZoom {...imageProps} /> */}

						<SideBySideMagnifier
							imageSrc={navdata.productImage.fields.file.url}
							imageAlt='image'
							cursorStyle='crosshair'
							alwaysInPlace={true}
							transitionSpeed={0.3}
							style={{ height: 'auto', width: 'auto' }}
						/>
						<div style={{ textAlign: 'center' }}>
							Slide over to zoom
						</div>
					</ModalBody>
				</Modal>
			</section>
		);
		var dinvalue =
			navdata.drugIdentificationNumberValue != undefined
				? navdata.drugIdentificationNumberValue
				: null;

		if (navdata.drugIdentificationNumberValue != undefined) {
			updatedDinValue = dinvalue.replace(/,/g, ' /');
		}

		if (
			this.state.function === undefined ||
			this.state.function === null ||
			this.state.function == ''
		) {
			Ingfunc = (
				<div className='ingredient-desc'>
					{this.state.isCABrand && this.state.casnumber && (
						<div className='cas-number'>
							CAS Number <span>{this.state.casnumber}</span>
						</div>
					)}
					{DesignatedLists}
				</div>
			);
		} else {
			// Ingfunc =
			let fragpar = false;
			// FOR DL active on EN-CA add || this.state.local.toLowerCase() === 'en-ca' in below if statement
			if (this.state.local.toLowerCase() === 'en-us') {
				Ingfunc = (
					<React.Fragment>
						{this.state.isCABrand && this.state.casnumber && (
							<div className='cas-number'>
								CAS Number <span>{this.state.casnumber}</span>
							</div>
						)}
						{DesignatedLists}
						{this.state.fragranceArr.includes(
							this.state.ingredientName
						) &&
							this.state.isCABrand &&
							this.state.articles.fields.ingredientList.forEach(
								(val) => {
									Array.isArray(val.subIngredients) &&
										val.subIngredients.forEach((item) => {
											if (
												item.ingredientName.toLowerCase() !==
												'nothing to report' &&
												item.ingredientName.toLowerCase() !==
												'none to report'
											) {
												fragpar = true;
												return null;
											}
										});
								}
							)}

						{this.state.fragranceArr.includes(
							this.state.ingredientName
						) &&
							this.state.isCABrand &&
							this.state.articles.fields.ingredientList.forEach(
								(val) => {
									Array.isArray(val.fragranceIngredients) &&
										val.fragranceIngredients.forEach(
											(item) => {
												if (
													item.ingredientName.toLowerCase() !==
													'nothing to report' &&
													item.ingredientName.toLowerCase() !==
													'none to report'
												) {
													fragpar = true;
													return null;
												}
											}
										);
								}
							)}
						{fragpar && (
							<p className='ingredient-desc no-bold'>
								For fragrance materials, not all CAS numbers are
								used in the US.
							</p>
						)}
						<div className='ingredient-desc' ref='desc'>
							<p id='desc'>
								{
									this.state.articles.fields.brand.fields
										.ingredientDescription
								}
							</p>
						</div>
					</React.Fragment>
				);
			} else {
				Ingfunc = (
					<div className='ingredient-desc' ref='desc'>
						<p id='desc'>
							{
								this.state.articles.fields.brand.fields
									.ingredientDescription
							}
						</p>
					</div>
				);
			}
		}
		if (
			this.state.FragranceState === undefined ||
			this.state.FragranceState === null ||
			this.state.FragranceState == ''
		) {
			Ingfuncs = (
				<div className='ingredient-desc'>
					{this.state.isCABrand &&
						this.state.casNumbers != undefined &&
						this.state.casNumbers != null ? (
							<React.Fragment>
								<div className='cas-number'>
									CAS Number <span>{this.state.casNumbers}</span>
								</div>
								{DesignatedLists}
							</React.Fragment>
						) : null}
				</div>
			);
		} else {
			// FOR DL active on EN-CA this.state.local.toLowerCase() === 'en-us' to below && condition & for en-ca make condition
			Ingfuncs = (
				<div className='ingredient-desc' ref='desc'>
					{this.state.isCABrand &&
						(this.state.casNumbers != undefined ||
							this.state.casNumbers != null) ? (
							<React.Fragment>
								<div className='cas-number'>
									CAS Number <span>{this.state.casNumbers}</span>
								</div>
								{DesignatedLists}
								<p id='desc'>
									{
										this.state.articles.fields.brand.fields
											.ingredientDescription
									}
								</p>
							</React.Fragment>
						) : null}
				</div>
			);
		}

		if (this.state.fragranceArr.includes(this.state.ingredientName)) {
			const subIngText =
				locale.toLowerCase() === 'fr-ca'
					? 'sous-ingrédients'
					: 'sub-ingredients';
			subIng = (
				<div className='frag-ing'>
					<h2 className='header3'>
						{this.state.articles.fields.ingredientList.some(
							(val) =>
								val &&
								(val.fragranceIngredients !== undefined ||
									val.subIngredients !== undefined) &&
								true
						) && subIngText.toUpperCase()}
					</h2>
					<ul className='list-item'>
						{this.state.articles.fields.ingredientList.map(
							(reference) => {
								if (
									reference &&
									reference.fragranceIngredients !==
									undefined &&
									reference.productName ===
									this.state.productNameSelected
								) {
									return reference.fragranceIngredients.map(
										(opt, id) => (
											<div className='ing' key={id}>
												<span className='pur'>
													{locale.toLowerCase() ===
														'fr-ca'
														? 'Ingrédient du parfum'
														: 'Fragrance Ingredient'}
												</span>
												<div className='fun'>
													{opt.functions}
												</div>
												{opt.ingredientName
													.toLowerCase()
													.trim() ===
													'nothing to report' ||
													opt.ingredientName
														.toLowerCase()
														.trim() ===
													'none to report' ? (
														<li
															className='header1'
															id='ingredient'
														// key={i}
														>
															{/* eslint-disable-next-line */}
															<a>
																{' '}
																{opt.ingredientName.toLowerCase()}{' '}
															</a>
														</li>
													) : (
														<li
															className='header1'
															id='sub-ingredient'
															onClick={() =>
																this.subingred(opt)
															}

														// key={i}
														>
															{/* eslint-disable-next-line */}
															<a>
																<span className='fren-ing'>
																	{this.props.local.toLowerCase() ==
																		'fr-ca' &&
																		navdata.brand
																			.fields
																			.frenchFragranceIngredientList
																		? navdata.brand.fields.frenchFragranceIngredientList.map(
																			(
																				ings
																			) => {
																				if (
																					opt.ingredientName
																						.toLowerCase()
																						.trim() ==
																					ings.EnglishIngredient.toLowerCase()
																				) {
																					return ings.FrenchIngredient.toLowerCase();
																				}
																			}
																		)
																		: opt.ingredientName.toLowerCase()}
																</span>
																<span className='main-ing'>
																	{opt.ingredientName.toLowerCase()}
																</span>
																{DLIcon(opt)}
																<i className='fa fa-chevron-right'></i>
															</a>
														</li>
													)}
											</div>
										)
									);
								} else if (
									reference &&
									reference.subIngredients !== undefined &&
									reference.productName ===
									this.state.productNameSelected
								) {
									return reference.subIngredients.map(
										(opt, id) => (
											<div className='ing' key={id}>
												<span className='pur'>
													{locale.toLowerCase() ===
														'fr-ca'
														? 'Ingrédient du parfum'
														: 'Fragrance Ingredient'}
												</span>
												<div className='fun'>
													{opt.functions}
												</div>
												{opt.ingredientName
													.toLowerCase()
													.trim() ===
													'nothing to report' ||
													opt.ingredientName
														.toLowerCase()
														.trim() ===
													'none to report' ? (
														<li
															className='header1'
															id='ingredient'

														// key={i}
														>
															{/* eslint-disable-next-line */}
															<a>
																{' '}
																{opt.ingredientName.toLowerCase()}{' '}
															</a>
														</li>
													) : (
														<li
															className='header1'
															id='sub-ingredient'
															onClick={() =>
																this.subingred(opt)
															}

														// key={i}
														>
															{/* eslint-disable-next-line */}
															<a>
																<span className='fren-ing'>
																	{this.props.local.toLowerCase() ==
																	'fr-ca' &&
																	navdata.brand
																		.fields
																		.frenchFragranceIngredientList
																		? navdata.brand.fields.frenchFragranceIngredientList.map(
																			(
																				ings
																			) => {
																				if (
																					opt.ingredientName
																						.toLowerCase()
																						.trim() ==
																					ings.EnglishIngredient.toLowerCase()
																				) {
																					return ings.FrenchIngredient.toLowerCase();
																				}
																			}
																		)
																		: opt.ingredientName.toLowerCase()}
																</span>
																<span className='main-ing'>
																	{opt.ingredientName.toLowerCase()}
																</span>
																{DLIcon(opt)}
																<i className='fa fa-chevron-right'></i>
															</a>
														</li>
													)}
											</div>
										)
									);
								} else {
									return null;
								}
							}
						)}
					</ul>
				</div>
			);
		} else {
			subIng = null;
		}

		if (this.props.article.fields.kit == 'YES') {
			//   var arr=[]
			var kitSelect = (
				<div className='kit-dropdown'>
					<div
						className='select-container'
						data-content={this.state.productNameSelected}
					>
						<select
							onChange={this.handleChange}
							className='select nonKit'
							id='select_id'
							defaultValue={this.state.dropdownArr[0]}
							style={{ fontSize: '0px' }}
						>
							{this.state.dropdownArr.length > 0 &&
								this.state.dropdownArr.map(
									(productName, id) => {
										const prodName = productName.includes(
											'|@|'
										)
											? productName.split('|@|')[1]
											: productName;
										return (
											<option
												value={JSON.stringify({
													value: prodName,
													fcgcas: productName.includes(
														'|@|'
													)
														? productName.split(
															'|@|'
														)[0]
														: null
												})}
												title={prodName}
												key={id}
											>
												{prodName.includes('~')
													? prodName.split('~')[0]
													: prodName}
											</option>
										);
									}
								)}
						</select>
					</div>
				</div>
			);

			let list = {};

			this.state.productIngredients.map((y) => {
				if (!list[y.ingredientType]) list[y.ingredientType] = [];
				list[y.ingredientType].push({
					ingredientName: y.ingredientName,
					purpose: y.purpose,
					function: y.function,
					sequenceOrder: y.sequenceOrder,
					chemicalOfConcern: y.chemicalOfConcern
				});
			});
			Array.prototype.sortBy = function (p) {
				return this.slice(0).sort(function (a, b) {
					return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
				});
			};

			if (
				this.state.productIngredients != undefined &&
				this.state.productIngredients.length > 0
			)
				if (
					this.state.productIngredients != undefined &&
					list[this.state.productIngredients[0].ingredientType] !=
					undefined
				) {
					var seqOd = list[
						this.state.productIngredients[0].ingredientType
					].map((x) => {
						var y = {};
						y['ingredientName'] = x['ingredientName'];
						y['purpose'] = x['purpose'];
						y['function'] = x['function'];
						y['sequenceOrder'] = parseInt(x['sequenceOrder']);
						return y;
					});
					list[this.state.productIngredients[0].ingredientType] =
						seqOd.sortBy('sequenceOrder');
				}

			var kit = (
				<div className='kit'>
					<div
						id='ingredient-list'
						className={this.state.condition ? ' hide' : 'show'}
					>
						{Object.keys(list)
							.sort()
							.map((key, id) => (
								<section key={id}>
									<h4 className='header3'>
										{key.split('@@*')[0]}
									</h4>
									<ul
										className='list-item'
										ref='ingList'
										id='list-item'
									>
										{list[key].map((ingredient, id) => {
											var seqOrd =
												ingredient.sequenceOrder;

											if (
												ingredient.purpose ||
												ingredient.function
											)
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														onClick={(event) =>
															this.handleClick(
																ingredient,
																event
															)
														}
														key={id}
														data-order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a>
															<span className='fren-ing'>
																{this.props.local.toLowerCase() ==
																	'fr-ca' &&
																	navdata.brand
																		.fields
																		.frenchIngredientList
																	? navdata.brand.fields.frenchIngredientList.map(
																		(
																			ing
																		) => {
																			if (
																				ingredient.ingredientName
																					.toLowerCase()
																					.trim() ==
																				ing.EnglishIngredient.toLowerCase().trim()
																			) {
																				return ing.FrenchIngredient;
																			}
																		}
																	)
																	: ingredient.ingredientName}
															</span>
															<span className='main-ing'>
																{
																	ingredient.ingredientName
																}
															</span>
															{DLIcon(ingredient)}
															<i className='fa fa-chevron-right'></i>
														</a>
													</li>
												);
											else
												return (
													<li
														key={id}
														ref='list'
														className='header1 notclick'
														id='ingredient'
														onClick={() =>
															this.handleClick(
																ingredient
															)
														}
														// key={i}

														data-order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a>
															{
																ingredient.ingredientName
															}
														</a>
													</li>
												);
										})}
									</ul>
								</section>
							))}
					</div>
				</div>
			);
		} else {
			let list = {};
			this.state.propsIngredientList.map((y) => {
				if (y !== undefined) {
					if (!list[y.ingredientType]) list[y.ingredientType] = [];
					list[y.ingredientType].push({
						ingredientName: y.ingredientName,
						purpose: y.purpose,
						function: y.function,
						sequenceOrder: y.sequenceOrder,
						casNumber: y.casNumber,
						chemicalOfConcern: y.chemicalOfConcern
					});
				}
			});
			Array.prototype.sortBy = function (p) {
				return this.slice(0).sort(function (a, b) {
					return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
				});
			};

			if (
				this.state.productIngredients != undefined &&
				this.state.productIngredients.length > 0
			)
				if (
					this.state.productIngredients != undefined &&
					list[this.state.productIngredients[0].ingredientType] !=
					undefined
				) {
					var seqOd2 = list[
						this.state.productIngredients[0].ingredientType
					].map((x) => {
						var y = {};
						y['ingredientName'] = x['ingredientName'];
						y['purpose'] = x['purpose'];
						y['function'] = x['function'];
						y['sequenceOrder'] = parseInt(x['sequenceOrder']);
						return y;
					});
					list[this.state.productIngredients[0].ingredientType] =
						seqOd2.sortBy('sequenceOrder');
				}
			let ingrFoot = navdata.ingredientFooterData;

			let footData = ingrFoot != undefined ? ingrFoot.split(/:/) : null;

			let footDataTitle = footData != undefined ? footData[0] : null;
			let footDataContent = footData != undefined ? footData[1] : null;

			let footercontent;

			if (footData != undefined || footData != null) {
				footercontent = (
					<section className='footerData'>
						<h4>{footDataTitle}</h4>
						<p className='footContent'>{footDataContent}</p>
					</section>
				);
			} else {
				footercontent = null;
			}

			var ingred = (
				<div
					id='ingredient-list'
					className={this.state.condition ? ' hide' : 'show'}
				>
					{Object.keys(list).map((key, i) => (
						<section key={i}>
							<h4 className='header3'>{key.split('@@*')[0]}</h4>
							<ul
								onClick={this.onClick}
								className='list-item'
								ref='ingList'
							>
								{list[key]
									.sort(
										(a, b) =>
											a.sequenceOrder - b.sequenceOrder
									)
									.map((ingredient, id) => {
										var ingreName =
											ingredient.ingredientName;
										var seqOrd = ingredient.sequenceOrder;
										if (
											ingreName.toLowerCase() !==
											'1,4-dioxane'
										) {
											if (
												ingredient.purpose ||
												ingredient.function
											) {
												return (
													<li
														key={id}
														ref='list'
														className='header1'
														id='ingredient'
														onClick={(event) =>
															this.handleClick(
																ingredient,
																event
															)
														}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a>
															<span className='fren-ing'>
																{this.props.local.toLowerCase() ==
																	'fr-ca' &&
																	navdata.brand
																		.fields
																		.frenchIngredientList
																	? navdata.brand.fields.frenchIngredientList.map(
																		(
																			ing
																		) => {
																			if (
																				ingredient.ingredientName ==
																				ing.EnglishIngredient
																			) {
																				return ing.FrenchIngredient;
																			}
																		}
																	)
																	: ingredient.ingredientName}
															</span>
															<span className='main-ing'>
																{
																	ingredient.ingredientName
																}
															</span>
															{DLIcon(ingredient)}
															<i className='fa fa-chevron-right'></i>
														</a>
													</li>
												);
											} else if (
												ingreName.endsWith(':') ||
												ingredient.purpose ||
												ingredient.function
											) {
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														// key={i}

														style={{
															cursor: 'default'
														}}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a
															style={{
																cursor: 'default'
															}}
															className='green'
														>
															{
																ingredient.ingredientName
															}
														</a>
													</li>
												);
											} else {
												return (
													<li
														ref='list'
														className='header1'
														id='ingredient'
														// key={i}

														style={{
															cursor: 'default'
														}}
														order={seqOrd}
													>
														{/* eslint-disable-next-line */}
														<a
															style={{
																cursor: 'default'
															}}
														>
															{
																ingredient.ingredientName
															}
														</a>
													</li>
												);
											}
										}
									})}
							</ul>
						</section>
					))}
					<div>{footercontent}</div>
				</div>
			);
		}

		if (locale.toLowerCase() === 'en-ca' || locale == 'fr-CA') {
			GTINtitle = (
				<div className='sub-header header2 gtins'>
					<b>{navdata.gtinTitle}</b> :{' '}
					{navdata.productImage.fields.title}
				</div>
			);
		} else if (navdata.brandName == 'All in ONEder') {
			GTINtitle = null;
		} else {
			GTINtitle = (
				<div className='sub-header header2'>
					{navdata.productImage.fields.title}
				</div>
			);
		}

		if (
			!(
				navdata.medicalDevicesLicenceTitle == undefined &&
				parseInt(navdata.medicalDevicesLicenceTitle) > 0
			)
		) {
			medicalDevice = null;
		} else {
			medicalDevice = (
				<div className='sub-header header2 mdl'>
					<b>{navdata.medicalDevicesLicenceTitle}</b> :{' '}
					{navdata.medicalDevicesLicenceNumber}
				</div>
			);
		}

		if (
			navdata.drugIdentificationNumberValue == undefined ||
			navdata.drugIdentificationNumberValue == 'N/A'
		) {
			drugIdentification = null;
		} else {
			drugIdentification = (
				<div className='sub-header header2 din'>
					<b>{navdata.drugIdentificationNumber}</b> :{' '}
					{updatedDinValue}
				</div>
			);
		}

		let npnval =
			navdata.naturalHealthProductsValue != undefined
				? navdata.naturalHealthProductsValue.toString()
				: null;
		let npn;

		if (npnval != undefined && npnval.length != 8) {
			npn = npnval.padStart(8, '0');
		} else {
			npn = npnval;
		}

		if (
			navdata.naturalHealthProductsValue == undefined ||
			parseInt(navdata.naturalHealthProductsValue) < 0 ||
			navdata.naturalHealthProductsValue == 'N/A'
		) {
			naturalhealth = null;
		} else {
			naturalhealth = (
				<div className='sub-header header2 nhp'>
					<b>{navdata.naturalHealthProductsTitle}</b> : {npn}
				</div>
			);
		}

		var netContent = navdata.nettProduct;
		var op = [];
		var finalNettContent;
		if (netContent !== '') {
			finalNettContent = netContent.includes('/')
				? locale.toLowerCase() === 'en-us'
					? netContent.split('/')[0]
					: netContent.split('/')[1]
				: netContent;
			// eslint-disable-next-line
			// var netValue = netContent.split('/').forEach((x) => {
			// 	if (x.split('.')[1] && x.split('.')[1].split(' ')[0] == '0') {
			// 		var y = x.replace('.0', '');
			// 		if (y.includes('mix')) {
			// 			let mix = 'mix';
			// 			op.push(mix);
			// 		} else if (y.includes('kit')) {
			// 			let kit = 'kit';
			// 			op.push(kit);
			// 		} else {
			// 			op.push(y);
			// 		}
			// 	} else if (x.includes('mix')) {
			// 		var z = 'mix';
			// 		op.push(z);
			// 	} else if (x.includes('kit')) {
			// 		var zx = 'kit';
			// 		op.push(zx);
			// 	} else {
			// 		op.push(x);
			// 	}
			// });
			// finalNettContent = op.join('/');
		}
		// FOR DL active on EN-CA remove local condition from below statement
		const DLDisclaimer =
			this.state.isCABrand &&
			this.state.local.toLowerCase() === 'en-us' &&
			this.state.cocarr && (
				<div className='dl-disclaimer'>
					{/* {`Ingredients below that appear on designated lists or any fragrance allergen included on Annex III of the EU Cosmetics Regulation No. 1223/2009 are indicated by the DL symbol`} */}
					Ingredients that appear on a California Cleaning Products
					Right to Know Act Designated List or are fragrance allergens
					included on Annex III of the EU Cosmetics Regulation No
					1223/2009 are indicated by the{' '}
					<span className='dl-icon'>DL</span>
					&nbsp;symbol.
					<br />
				</div>
			);

		return (
			<div>
				<div className='products'>
					<Container fluid>
						<Row>
							<Col sm='7' xs='8'>
								<div className='sub-header' id='brand-name'>
									{navdata.brandName}
								</div>
								<div className='header1' id='product-name'>
									{navdata.productName}
								</div>
								<div
									className='sub-header header2'
									id='nett-content'
								>
									{finalNettContent}
								</div>
							</Col>
							<Col sm='5' xs='4'>
								<div className='product-img' id='product-img'>
									<div className='product-section'>
										{newChanges}
										{medicalDevice}
										{drugIdentification}
										{naturalhealth}
										{GTINtitle}
									</div>
								</div>
							</Col>
							<Col
								sm='6'
								xs='12'
								className='sp-dropdown'
								id='kit-dropdown'
							>
								{this.state.dropdownArr && kitSelect}
							</Col>
						</Row>
					</Container>
				</div>
				<div className='zoomImage'>
					<div className='section-container'>
						<Container>
							<Row>
								<Col sm='12'>
									<img
										src={
											navdata.productImage.fields.file.url
										}
										alt={
											navdata.productImage.fields.file.url
										}
									/>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				<Tabs className='tabs'>
					<TabList
						id='tab'
						style={{
							color: '#06DCD6',
							borderWidth: 0,
							position: this.state.scrolling
								? 'fixed'
								: 'relative',
							top: 0,
							width: '100vw',
							zIndex: 1
						}}
					>
						{/* <Tab onClick={this.tabReset}><div><i className="fa fa-list"></i>{navdata.nonFoodInstruction.fields.ingredientsHeading}</div></Tab>
    					<Tab onClick={this.tabReset}><div><i className="fa fa-exclamation-triangle"></i>{navdata.nonFoodInstruction.fields.advisoriesHandlingHeading}</div></Tab>
    					<Tab onClick={this.tabReset}><div><i className="fa fa-hand-o-up"></i><span>{navdata.nonFoodInstruction.fields.usageInstructionsHeading}</span></div></Tab>
    					<Tab onClick={this.tabReset}><div><i className="fa fa-info-circle"></i>{navdata.nonFoodInstruction.fields.otherInformationHeading}</div></Tab>
						<Tab onClick={this.tabReset}><div><i className="fa fa-share-square"></i>{navdata.companyCommonData.fields.companyHeading}</div></Tab> */}

						{this.props.local === 'en-US' ||
							this.props.local === 'en-ca' ||
							this.props.local === 'en-CA' ? (
								Object.keys(headings).map((val, id) => {
									return (
										<Tab
											onClick={this.tabReset}
											key={headings[val]['name']}
										>
											<div id={headings[val]['id']}>
												<i
													className={
														headings[val]['icon']
													}
												/>
												{headings[val]['name']}
											</div>
										</Tab>
									);
								})
							) : (
								<React.Fragment>
									<Tab onClick={this.tabReset}>
										<div id='ingredient-tab'>
											<i className='fa fa-list' />
											{
												navdata.nonFoodInstruction.fields
													.ingredientsHeading
											}
										</div>
									</Tab>
									<Tab onClick={this.tabReset}>
										<div>
											<i className='fa fa-exclamation-triangle' />

											{
												navdata.nonFoodInstruction.fields
													.advisoriesHandlingHeading
											}
										</div>
									</Tab>
									<Tab onClick={this.tabReset}>
										<div>
											<i className='fa fa-hand-o-up' />
											<span>
												{
													navdata.nonFoodInstruction
														.fields
														.usageInstructionsHeading
												}
											</span>
										</div>
									</Tab>
									<Tab onClick={this.tabReset}>
										<div>
											<i className='fa fa-info-circle' />
											{
												navdata.nonFoodInstruction.fields
													.otherInformationHeading
											}
										</div>
									</Tab>
									<Tab onClick={this.tabReset}>
										<div>
											<i className='fa fa-share-square' />
											{
												navdata.companyCommonData.fields
													.companyHeading
											}
										</div>
									</Tab>
								</React.Fragment>
							)}
					</TabList>

					<TabPanel>
						<Container fluid>
							<Row>
								<Col>
									<DrugFact article={this.props.article} />

									<div id='ingredients'>
										<Container fluid className='top-20'>
											<div className='ingredients'>
												{DLDisclaimer}
												<Row>
													<Col sm='4'>
														{kit}
														{ingred}
													</Col>
													<Col sm='8'>
														<div className='ingredient-content'>
															<Sticky>
																<div
																	ref='element'
																	className='content-list'
																>
																	<div
																		className='back'
																		id='all-ingredient-list'
																		onClick={() =>
																			this.backClick()
																		}
																	>
																		<i
																			className='fa fa-chevron-left fa-5'
																			aria-hidden='true'
																		></i>
																	</div>
																	<p
																		className='header1 title'
																		id='ing-title'
																	>
																		{
																			this
																				.state
																				.ingredientName
																		}
																	</p>
																	<p
																		className='paragraph purpose'
																		id='ing-purpose'
																	>
																		{this
																			.state
																			.purpose &&
																			this.state.purpose.split(
																				'~'
																			)[0]}
																	</p>
																	<p
																		className='paragraph function'
																		id='ing-function'
																	>
																		{this
																			.state
																			.function &&
																			this.state.function.split(
																				'~'
																			)[0]}
																	</p>
																</div>

																{Ingfunc}
															</Sticky>
															{subIng}
														</div>

														<div className='subIngrdients'>
															<div id='sub-ing'>
																<Sticky>
																	<div
																		ref='elements'
																		className='content-list frag'
																	>
																		<div
																			className='back'
																			id='all-fragrance'
																			onClick={
																				this
																					.backtoFragrance
																			}
																		>
																			<i
																				className='fa fa-chevron-left fa-5'
																				aria-hidden='true'
																			></i>
																		</div>
																		<p
																			className='header1 title'
																			id='ing-title'
																		>
																			{
																				this
																					.state
																					.ingredientNames
																			}
																		</p>
																		<p
																			className='paragraph purpose'
																			id='ing-purpose'
																		>
																			{locale.toLowerCase() ===
																				'fr-ca'
																				? 'Ingrédient du parfum'
																				: 'Fragrance Ingredient'}
																		</p>
																		<p
																			className='paragraph function'
																			id='ing-function'
																		>
																			{
																				this
																					.state
																					.FragranceState
																			}
																		</p>
																		<p className='paragraph function'>
																			{
																				this
																					.state
																					.FragranceCons + ' '
																			}
																			<a
																				href={
																					this
																						.state
																						.FragranceURL
																				}
																				target='_blank'
																				rel='noopener noreferrer'
																				style={{
																					color: '#fff',
																					textDecoration:
																						'underline',
																					wordBreak:
																						'break-all'
																				}}
																			>
																				{
																					this
																						.state
																						.FragranceURL
																				}
																			</a>
																		</p>
																	</div>
																	{Ingfuncs}
																</Sticky>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</Container>
									</div>
								</Col>
							</Row>
						</Container>
					</TabPanel>
					<TabPanel>
						<div id='allergens'>
							<Advisory
								article={this.props.article}
								local={this.props.local}
								cocarr={this.state.cocarr}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div id='usagem'>
							<Usage
								article={this.props.article}
								local={this.props.local}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div id='otherm'>
							<Otherinfo
								article={this.props.article}
								local={this.props.local}
							/>
						</div>
					</TabPanel>
					<TabPanel>
						<div id='companym'>
							<Company article={this.props.article} />
						</div>
					</TabPanel>
				</Tabs>
			</div>
		);
	}
}
