/*eslint-disable array-callback-return*/
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import anchorme from 'anchorme';
import Cocdata from '../../JSONData/cocdata';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class Advisory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			articles: props.article || [],
			modalLink: '',
			modal3: false,
			modalLink2: '',
			toggleCoc: false,
			gtin: props.article.fields.gtin,
			cocBrands: [
				'9 Elements',
				'All in Oneder',
				'Ariel',
				'Bounce',
				'Bounce Professional',
				'Braun',
				'Cascade',
				'Cheer',
				'Dawn',
				'Dawn Professional',
				'Downy',
				'Downy Professional',
				'Dreft',
				'Era',
				'Febreze',
				'Febreze Professional',
				'Gain',
				'Home Made Simple',
				'Ivory Snow',
				'Joy',
				'Microban',
				'Mr. Clean',
				'Mr Clean',
				'NBD.',
				'Studio by Tide',
				'Studio for Tide',
				'Swiffer',
				'Tide',
				'Tide Professional'
			],
			noncocSDSBrands: [
				'Old Spice',
				'Pantene',
				'Gillette',
				'Olay',
				'Herbal Essences',
				'Secret',
				'Ivory',
				'Head & Shoulders',
				'Hair Food',
				'Aussie',
				'Venus',
				'Waterless',
				'Glee',
				'Burts Bees',
				'Safeguard',
				'Derma Geek',
				'My Black Is Beautiful',
				'Spring & Vine',
				'Good Skin MD',
				'Quiet & Roar',
				'Native',
				'Nou',
				'Venus'
			]
		};
		this.toggle = this.toggle.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggleCOCModal = this.toggleCOCModal.bind(this);
	}

	toggle(url) {
		this.setState({
			modal: !this.state.modal,
			modalLink: url
		});
	}

	toggle3(url) {
		this.setState({
			modal3: !this.state.modal3,
			modalLink2: url
		});
	}

	componentWillMount() {
		let arr = [];
		this.props.cocarr.map((val) => {
			Cocdata[val] !== undefined && arr.push(Cocdata[val]);
		});
		this.setState({ modalArr: arr });
	}

	toggleCOCModal() {
		this.setState({ toggleCoc: !this.state.toggleCoc });
	}

	render() {
		var warningDescr;
		let sustainability;
		let warningDescr2;
		let sustainlink;
		let sustain =
			this.props.article.fields.nonFoodInstruction.fields
				.sustainabilityDescription;
		if (sustain !== null && sustain !== undefined) {
			sustainlink = anchorme(sustain, {
				attributes: [
					{
						name: 'target',
						value: '_blank'
					}
				]
			});
		} else {
			sustainlink = null;
		}

		const advisorydata = this.props.article.fields;

		let warnText;
		const { brandName } = advisorydata.brand.fields;
		if (
			advisorydata.nonFoodInstruction.fields.warningDescription !==
			undefined
		) {
			if (
				this.props.local === 'en-US' &&
				brandName.toLowerCase() === 'zevo instant action spray'
			) {
				warnText = (
					<React.Fragment>
						<p className='paragraph' id='human-health'>
							{
								advisorydata.nonFoodInstruction.fields.warningDescription.split(
									':'
								)[0]
							}
						</p>
						<p className='paragraph' id='human-health'>
							{
								advisorydata.nonFoodInstruction.fields.warningDescription.split(
									':'
								)[1]
							}
							:
						</p>
						<p className='paragraph' id='human-health'>
							{
								advisorydata.nonFoodInstruction.fields.warningDescription.split(
									':'
								)[2]
							}
						</p>
					</React.Fragment>
				);
			} else {
				warnText = (
					<p className='paragraph' id='human-health'>
						{
							advisorydata.nonFoodInstruction.fields
								.warningDescription
						}
					</p>
				);
			}
		}

		// eslint-disable-next-line no-unused-vars
		const isCABrand = this.state.articles.fields.brandName.trim() === 'Ivory' ?
			this.state.articles.fields.productName.toLowerCase().includes('detergent') ||
			this.state.articles.fields.productName.toLowerCase().includes('dish soap') :
			this.state.cocBrands.find(
				(cab) =>
					cab.toLowerCase().replace(/ /g, '').replace(/\./g, '') ===
					this.state.articles.fields.brandName
						.toLowerCase()
						.replace(/ /g, '')
						.replace(/\./g, '')
			);
		// eslint-disable-next-line no-unused-vars
		const isNonCASDSBrand = this.state.noncocSDSBrands.find(
			(noncaBrand) => noncaBrand.toLowerCase().replace(/ /g, '').replace(/\./g, '') ===
				this.state.articles.fields.brandName.toLowerCase().replace(/ /g, '').replace(/\./g, '')
		);
		if (
			advisorydata.nonFoodInstruction.fields.warningDescription !==
			undefined ||
			(advisorydata.sdsPdf !== undefined &&
				advisorydata.sdsPdf.fields.file.url !== undefined)
		) {
			warningDescr = (
				<div className='definition'>
					<Row>
						<Col sm='12' xs='12'>
							<h4 className='header4' id='human-health-header'>
								{this.props.local === 'en-US' ||
									this.props.local.toLowerCase() === 'en-ca'
									? (advisorydata.nonFoodInstruction.fields.warningTitle =
										'Human Health')
									: advisorydata.nonFoodInstruction.fields
										.warningTitle}
							</h4>

							{warnText}

							{advisorydata.sdsPdf !== undefined &&
								advisorydata.sdsPdf.map((pdf, key) => {
									return this.props.article.fields.gtin !==
										'00037000875628' &&
										this.props.article.fields.gtin !==
										'00037000927983' ? (
											<p
												className='paragraph'
												id='sds'
												key={key}
											>
												{/* eslint-disable-next-line */}
											<a
													onClick={(e) => {
														e.preventDefault();
														this.toggle(
															pdf.fields.file.url
														);
													}}
												>
												Click here for Safety Data Sheet
												</a>
											</p>
										) : (
											<p className='paragraph'>
												{/* eslint-disable-next-line */}
											<a
													onClick={(e) => {
														e.preventDefault();
														this.toggle(
															pdf.fields.file.url
														);
													}}
												>
												Click here for Safety Data Sheet
												</a>
											</p>
										);
								})}
						</Col>
					</Row>
					<Modal
						isOpen={this.state.modal}
						toggle={this.toggle}
						className='center-align pdf'
					>
						<ModalHeader toggle={this.toggle}>
							Safety Data Sheets
						</ModalHeader>
						<ModalBody>
							{
								// <object width='800' height='500' data={this.state.modalLink} />
								// eslint-disable-next-line
								<iframe
									id='pdfviewer'
									src={this.state.modalLink}
									frameborder='0'
									width='800'
									height='500'
								/>
							}
						</ModalBody>
					</Modal>
					<Modal
						isOpen={this.state.toggleCoc}
						toggle={this.toggleCOCModal}
						className='COC_popup'
					>
						<ModalHeader toggle={this.toggleCOCModal}></ModalHeader>
						<ModalBody>
							{this.state.modalArr.map((val, key) => {
								return (
									<div className='COCModal' key={key}>
										<p id='coc'>
											<a
												href={val.url}
												target='_blank'
												rel='noopener noreferrer'
											>
												<strong id='coc-title'>
													{val.name}
												</strong>
											</a>
											<span id='coc-desc'>
												{val.description}
											</span>
										</p>
									</div>
								);
							})}
						</ModalBody>
					</Modal>
				</div>
			);
		} else {
			warningDescr = null;
		}

		if (
			advisorydata.nonFoodInstruction.fields
				.storageInstructionDescription === undefined
		) {
			warningDescr2 = null;
		} else {
			if (
				advisorydata.nonFoodInstruction.fields
					.storageInstructionDescription === undefined ||
				advisorydata.nonFoodInstruction.fields
					.storageInstructionDescription === 'Not Applicable'
			) {
				warningDescr2 = null;
			} else {
				warningDescr2 = (
					<div className='definition'>
						<Row>
							<Col sm='12' xs='12'>
								<h4
									className='header4'
									id='storage-disposal-header'
								>
									{this.props.local === 'en-US' ||
										this.props.local.toLowerCase() === 'en-ca'
										? 'Storage and Disposal'
										: advisorydata.nonFoodInstruction.fields
											.storageInstructionTitle}
								</h4>
								<p id='storage-disposal'>
									{
										advisorydata.nonFoodInstruction.fields
											.storageInstructionDescription
									}
								</p>
							</Col>
						</Row>
					</div>
				);
			}
		}

		if (
			advisorydata.nonFoodInstruction.fields.sustainabilityDescription ===
			undefined ||
			advisorydata.nonFoodInstruction.fields.sustainabilityDescription ===
			'N/A' ||
			advisorydata.nonFoodInstruction.fields.sustainabilityDescription ===
			'Not Available' ||
			advisorydata.nonFoodInstruction.fields.sustainabilityDescription ===
			'NA' ||
			advisorydata.nonFoodInstruction.fields.sustainabilityDescription ===
			'Not Applicable'
		) {
			sustainability = null;
		} else {
			sustainability = (
				<div className='definition'>
					<Row>
						<Col sm='12' xs='12'>
							<h4 className='header4' id='environment-header'>
								{/* eslint-disable-next-line */}
								<a>Environment</a>
							</h4>

							<p
								id='environment'
								className='paragraph'
								dangerouslySetInnerHTML={{
									__html: sustainlink
								}}
							></p>
						</Col>
					</Row>
				</div>
			);
		}

		return (
			<Container fluid className='top-20'>
				{warningDescr}
				{this.props.local === 'en-US' ||
					this.props.local.toLowerCase() === 'en-ca'
					? warningDescr2
					: null}
				{this.props.local === 'en-US' ||
					this.props.local.toLowerCase() === 'en-ca'
					? sustainability
					: ''}
				{this.state.gtin === '11137000739739' &&
					this.props.cocarr.length > 0 &&
					this.state.modalArr.length > 0 && (
					<div className='definition'>
						{this.props.local === 'en-US' && (
							<p
								className='paragraph'
								id='chemical-concern-text'
							>
									This product contains one or more of the
									ingredients that appear on designated
									government lists. For more
									information,&nbsp;
								<a href=''
									onClick={(e) => {
										e.preventDefault();
										this.toggleCOCModal();
									}}
								>
										click here.
								</a>
							</p>
						)}
					</div>
				)}
			</Container>
		);
	}
}
