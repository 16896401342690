/* eslint-disable indent */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import BodyComponent from './components/BodyComponent/body';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ScrollToTop from 'react-scroll-up';
import NutritionComponent from './components/BodyComponent/body-nutrition';
import Socialshare from './components/Social-share/social-share';
import { Container, Row } from 'reactstrap';
import E404 from './components/epage/e404';
import E500 from './components/epage/e500';
import Loader from 'react-loader-spinner';

var GTIN = '';
var LOCAL = '';
var isInvalid = true;
var regex = RegExp('^([0-9]{14})(.html)((#[a-z]+)?)$');

const functionKey = 'rcFCU9OGRqabB1wIlttDM8MKKGU8aVb0YquO2xw6uiW9a7SGraQhlw==';
export default class Main extends React.Component {
	constructor() {
		super();
		var path = window.location.pathname;
		var len = path.match(new RegExp('/', 'g')).length;
		var arr = path.split('/');
		if (len === 2) {
			if (!regex.test(arr[2])) {
				isInvalid = false;
			}

			if(arr[1] == 'en-us' || arr[1] == 'en-US'){
				LOCAL = 'en-US';
				GTIN = arr[2].split('.')[0];
			} else if(arr[1] == 'en-ca' || arr[1] == 'en-CA') {
				LOCAL = 'en-ca';
				GTIN = arr[2].split('.')[0];
			} else if(arr[1] == 'fr-ca' || arr[1] == 'fr-CA'){
				LOCAL = 'fr-CA';
				GTIN = arr[2].split('.')[0];
			} else {
				LOCAL = 'en-US';
				GTIN = arr[2].split('.')[0];
			}

		} else if (len === 1) {
			GTIN = arr[1].split('.')[0];
			LOCAL = 'en-US';
			if (!regex.test(arr[1])) {
				isInvalid = false;
			}
		}

		LOCAL.includes("en") ? document.documentElement.lang = 'en' : LOCAL.includes("fr") ? document.documentElement.lang = 'fr' : document.documentElement.lang = 'en'
		
		this.state = {
			articles: [],
			error: [],
			productnotfound: '',
			servererror: '',
			gtin: GTIN,
			local: LOCAL,
			isInvalid: isInvalid,
			gtinNotPresent: false,
			condition: false,
			isloading: true,
		};
	}

	componentDidMount() {
		axios
			.get(
				'https://az-na-smartlabel-prod-functionapp-api.pgcloud.com/api/getproductdetails?gtin=' +
				GTIN +
				'&locale=' +
				LOCAL,
				{
					headers: {
						'x-functions-key': functionKey,
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Allow-Methods': 'GET',
					},
				}
			)
			.then((response) => {
				var temp = [];
				if (response.status === 200) {
					temp[0] = response.data;
					this.setState({
						articles: temp,
						isloading: false,
					});
				}
			})
			.catch((response) => {
				var invalidpath = [];
				var serverissue = [];

				if (response.response.status === 500) {
					serverissue[0] = response.response.data;
					this.setState({
						servererror: serverissue,
						isloading: false,
					});
				} else if (response.response.status === 404) {
					invalidpath[0] = response.response.data;
					this.setState({
						productnotfound: invalidpath,
						isloading: false,
					});
				}
			});
	}

	render() {
		// const pathUrl = this.state.gtin + '.html';
		const local = this.state.local;
		let pathName = window.location.pathname.split('/').slice(0, -1).join('/') + '/';
		let redirectCA;
		// let path = pathName.toLowerCase();

		if (pathName === '/' || pathName === '/en-us/' || pathName === '/en-US/') {
			redirectCA = (
				<div>
					<Redirect from='/' to={`/en-us/${this.state.gtin}.html`} />
				</div>
			);
		} else if (pathName === '/en-CA/' || pathName === '/en-ca/') {
			redirectCA = (
				<div>
					<Redirect from='/en-CA/' to={`/en-ca/${this.state.gtin}.html`} />
				</div>
			);
		} else if (pathName === '/fr-CA/' || pathName === '/fr-ca/') {
			redirectCA = (
				<div>
					<Redirect from='/fr-ca/' to={`/fr-CA/${this.state.gtin}.html`} />
				</div>
			);
		} else {
			redirectCA = (
				<div>
					<Redirect from='/' to={`/en-us/${this.state.gtin}.html`} />
				</div>
			);
		}

		let data;
		if (this.state.isloading) {
			data = (
				<div className='loader_img'>
					<Loader type='Oval' color='#21409a' height='100' width='100' />
				</div>
			);
		} else {
			data = (
				<Container fluid>
					<Route
						path='/en-us/:pathUrl'
						exact
						render={() => (
							<Row>
								{this.state.articles.map((article, i) => {
									let panelData = article.fields.panel;
									const subbrandvalue =
										article.fields.subBrand &&
											article.fields.subBrand !== 'N/A' &&
											article.fields.subBrand !== 'Not Applicable' &&
											article.fields.subBrand !== '' &&
											article.fields.subBrand !== ' '
											? article.fields.subBrand
											: '';

									let panelvalue = panelData === undefined ? null : panelData.toUpperCase();
									return (
										<div className='content' key={i} name='top'>
											<Helmet>
												<meta charSet='utf-8' />
												<title>{article.fields.productName} - SmartLabel™</title>
												<meta
													name='viewport'
													content='width=device-width, initial-scale=1, shrink-to-fit=no'
												/>
												<link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
												<script type='text/javascript'>{`												
											var PGdataLayer = {"user":{"ID":"","type":"anonymous","ecommID":"","mediaID":""},"session":{"leadkey":"","useragent":""},"page":{"url":"","title":""},"content":{"type":"Home","sub-category":"","category":""},"product":{"gtin":"${article.fields.gtin}","name":"${article.fields.productName}","brand":"${article.fields.brand.fields.brandName}","category":"","sub_brand":"${subbrandvalue}","segment":""},"event":[{"category":"","action":"","label":"","value":""}],"cd":[{"name":"","id":"","value":""}],"GTM":{"SiteLocale":"en-US","SiteTouchpoint":"43447","SiteCountry":"US","SiteBrand": "${article.fields.brand.fields.brandName}","SiteLanguage":"English","SitePlatform":"${article.fields.brand.fields.aboutBrand}","SiteEnvironment":"PROD","SiteHost":"Azure","SiteTechnicalAgency":"Mindtree","SiteStatus":"Live","SiteLocalContainer":"","BINPlatform":"","GoogleAnalyticsGA4MeasurementID":"G-HHJPGWLS7Y","GoogleAnalyticsLocal":"UA-125846340-1","GoogleAnalyticsBrand":"","GoogleAnalyticsSiteSpeedSampleRate":"High","GoogleAnalyticsAllowLinker":"false","GoogleAnalyticsLinkerDomains":"","GoogleAnalyticsEnableOptimizely":"false","GoogleAnalyticsOptimizeContainerID":"","GoogleAnalyticsReportingView":"181825581","ConsentOverlay": "OneTrust",
											"ConsentOverlayID": "2db88a3e-b16a-4605-b35a-43ec1a9727be", "SitePrivacyProtection": "CCPA","FacebookConnectAppID":"","FacebookConnectLocale":"","FacebookRemarketingID":""}};														
										`}</script>
												<script>{`
											(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N94XXFB');
										`}</script>
											</Helmet>

											<Header article={article} local={local} />

											{panelvalue === 'NUTRITION' ||
												panelvalue === 'NUTRIENT' ||
												panelvalue === 'SUPPLEMENT' ? (
												<NutritionComponent article={article} local={this.state.local} />
											) : (
												<BodyComponent article={article} local={local} />
											)}

											<Footer article={article} local={local} />

											<ScrollToTop showUnder={200}>
												<div className='ScrollTo'>
													<i className='fa fa-arrow-circle-o-up' aria-hidden='true'></i>
												</div>
											</ScrollToTop>

											<Socialshare />
										</div>
									);
								})}
							</Row>
						)}
					/>

					<Route
						path='/en-ca/:pathUrl'
						exact
						render={() => (
							<Row>
								{this.state.articles.map((article, i) => {
									const subbrandvalue =
										article.fields.subBrand &&
											article.fields.subBrand !== 'N/A' &&
											article.fields.subBrand !== 'Not Applicable' &&
											article.fields.subBrand !== '' &&
											article.fields.subBrand !== ' '
											? article.fields.subBrand
											: '';

									let panelData = article.fields.panel;
									let panelvalue = panelData === undefined ? null : panelData.toUpperCase();
									return (
										<div className='content' key={i} name='top'>
											<Helmet>
												<meta charSet='utf-8' />
												<title>{article.fields.productName} - SmartLabel™</title>
												<meta
													name='viewport'
													content='width=device-width, initial-scale=1, shrink-to-fit=no'
												/>
												<link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
												<script type='text/javascript'>{`
											var PGdataLayer = {"user":{"ID":"","type":"anonymous","ecommID":"","mediaID":""},"session":{"leadkey":"","useragent":""},"page":{"url":"","title":""},"content":{"type":"Home","sub-category":"","category":""},"product":{"gtin":"${article.fields.gtin}","name":"${article.fields.productName}","brand":"${article.fields.brand.fields.brandName}","category":"","sub_brand":"${subbrandvalue}","segment":""},"event":[{"category":"","action":"","label":"","value":""}],"cd":[{"name":"","id":"","value":""}],"GTM":{"SiteLocale":"en-CA","SiteTouchpoint":"43446","SiteCountry":"CA","SiteBrand": "${article.fields.brand.fields.brandName}","SiteLanguage":"English","SitePlatform":"${article.fields.brand.fields.aboutBrand}","SiteEnvironment":"PROD","SiteHost":"Azure","SiteTechnicalAgency":"Mindtree","SiteStatus":"Live","SiteLocalContainer":"","BINPlatform":"","GoogleAnalyticsGA4MeasurementID":"G-BFG4BWXMWX","GoogleAnalyticsLocal":"UA-125846340-2","GoogleAnalyticsBrand":"","GoogleAnalyticsSiteSpeedSampleRate":"High","GoogleAnalyticsAllowLinker":"false","GoogleAnalyticsLinkerDomains":"","GoogleAnalyticsEnableOptimizely":"false","GoogleAnalyticsOptimizeContainerID":"","GoogleAnalyticsReportingView":"181811311","ConsentOverlay": "OneTrust",
											"ConsentOverlayID": "htmllang/c12404ed-9da8-448e-a3f0-7395d1cccb4e", "SitePrivacyProtection": "CA","FacebookConnectAppID":"","FacebookConnectLocale":"","FacebookRemarketingID":""}}	
										`}</script>
												<script>{`
											(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N94XXFB');
										`}</script>
											</Helmet>

											<Header article={article} />

											{panelvalue === 'NUTRITION' || panelvalue === 'NUTRIENT' ? (
												<NutritionComponent article={article} />
											) : (
												<BodyComponent article={article} local={local} />
											)}

											<Footer article={article} />

											<ScrollToTop showUnder={200}>
												<div className='ScrollTo'>
													<i className='fa fa-arrow-circle-o-up' aria-hidden='true'></i>
												</div>
											</ScrollToTop>

											<Socialshare />
										</div>
									);
								})}
							</Row>
						)}
					/>

					{redirectCA}

					<Route
						path='/fr-CA/:pathUrl'
						render={() => (
							<Row>
								{this.state.articles.map((article, i) => {
									const subbrandvalue =
										article.fields.subBrand &&
											article.fields.subBrand !== 'N/A' &&
											article.fields.subBrand !== 'Not Applicable' &&
											article.fields.subBrand !== '' &&
											article.fields.subBrand !== ' '
											? article.fields.subBrand
											: '';
									let panelData = article.fields.panel;
									let panelvalue = panelData === undefined ? null : panelData.toUpperCase();
									return (
										<div className='content' key={i} name='top'>
											<Helmet>
												<meta charSet='utf-8' />
												<title>{article.fields.productName} - SmartLabel™</title>
												<meta
													name='viewport'
													content='width=device-width, initial-scale=1, shrink-to-fit=no'
												/>
												<link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
												<script type='text/javascript'>{`
											var PGdataLayer = {"user":{"ID":"","type":"anonymous","ecommID":"","mediaID":""},"session":{"leadkey":"","useragent":""},"page":{"url":"","title":""},"content":{"type":"Home","sub-category":"","category":""},"product":{"gtin":"${article.fields.gtin}","name":"${article.fields.productName}","brand":"${article.fields.brand.fields.brandName}","category":"","sub_brand":"${subbrandvalue}","segment":""},"event":[{"category":"","action":"","label":"","value":""}],"cd":[{"name":"","id":"","value":""}],"GTM":{"SiteLocale":"fr-CA","SiteTouchpoint":"43445","SiteCountry":"CA","SiteBrand": "${article.fields.brand.fields.brandName}","SiteLanguage":"français","SitePlatform":"${article.fields.brand.fields.aboutBrand}","SiteEnvironment":"PROD","SiteHost":"Azure","SiteTechnicalAgency":"Mindtree","SiteStatus":"Live","SiteLocalContainer":"","BINPlatform":"","GoogleAnalyticsGA4MeasurementID":"G-9EWYNEY6RM","GoogleAnalyticsLocal":"UA-125846340-3","GoogleAnalyticsBrand":"","GoogleAnalyticsSiteSpeedSampleRate":"High","GoogleAnalyticsAllowLinker":"false","GoogleAnalyticsLinkerDomains":"","GoogleAnalyticsEnableOptimizely":"false","GoogleAnalyticsOptimizeContainerID":"","GoogleAnalyticsReportingView":"181834883","ConsentOverlay": "OneTrust",
											"ConsentOverlayID": "htmllang/2b6e89ee-2ace-43bb-8820-b948654ca1fa", "SitePrivacyProtection": "CA","FacebookConnectAppID":"","FacebookConnectLocale":"","FacebookRemarketingID":""}}		
										`}</script>
												<script>{`
											(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N94XXFB');
										`}</script>
											</Helmet>

											<Header article={article} />

											{panelvalue === 'NUTRITION' || panelvalue === 'NUTRIENT' ? (
												<NutritionComponent article={article} />
											) : (
												<BodyComponent article={article} local={local} />
											)}

											<Footer article={article} />

											<ScrollToTop showUnder={200}>
												<div className='ScrollTo'>
													<i className='fa fa-arrow-circle-o-up' aria-hidden='true'></i>
												</div>
											</ScrollToTop>

											<Socialshare />
										</div>
									);
								})}
							</Row>
						)}
					/>
				</Container>
			);
		}

		return this.state.productnotfound ? (
			<div>
				{this.state.productnotfound.map((errormap1, i) => {
					let errortemp1 = errormap1;

					return (
						<div key={i}>
							<Helmet>
								<meta charSet='utf-8' />
								<title>404 - SmartLabel™</title>
								<meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
								<link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
							</Helmet>

							<E404 errortemp1={errortemp1} />
						</div>
					);
				})}
			</div>
		) : this.state.servererror ? (
			<div>
				{this.state.servererror.map((errormap1, i) => {
					let errortemp1 = errormap1;

					return (
						<div key={i}>
							<Helmet>
								<meta charSet='utf-8' />
								<title>500 - SmartLabel™</title>
								<meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
								<link rel='manifest' href='%PUBLIC_URL%/manifest.json' />
							</Helmet>
							<E500 errortemp1={errortemp1} />
						</div>
					);
				})}
			</div>
		) : (
			<div>{data}</div>
		);
	}
}
